import { Fragment, useState, useEffect } from 'react'
import classNames from 'classnames'
import { Combobox as Cbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'

export type ComboboxItem = {
  id: string | number
  name: string
}

type ComboboxProps = {
  label?: string
  icon?: JSX.Element
  placeholder?: string
  items: ComboboxItem[]
  value?: ComboboxItem
  disabled?: boolean
  onChange: (selected: ComboboxItem) => void
}

export function Combobox({
  label,
  icon,
  placeholder,
  items,
  value,
  disabled = false,
  onChange
}: ComboboxProps) {
  // Component state
  const [selectedItem, setSelectedItem] = useState<ComboboxItem | undefined>(value)
  const [query, setQuery] = useState<string>('')

  const filteredItems =
    query === ''
      ? items
      : items.filter((i) =>
        i.name
          .toLowerCase()
          .replace(/\s+/g, '')
          .includes(query.toLowerCase().replace(/\s+/g, ''))
      )

  // Watchers
  useEffect(() => {
    if (value) {
      setSelectedItem(value)
    }
  }, [value])

  return (
    <Cbox value={selectedItem} disabled={disabled} onChange={(i) => { setSelectedItem(i); onChange(i); }}>
      {label && <Cbox.Label className="block text-sm font-medium text-gray-700">{label}</Cbox.Label>}
      <div className={classNames('relative rounded-md shadow-sm', { 'mt-1': label })}>
        <div className="relative w-full cursor-default overflow-hidden rounded-md border border-gray-300 shadow-sm bg-white text-left focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
          {icon && (
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3 text-gray-400 child:w-4 child:h-4">
              {icon}
            </div>
          )}
          <Cbox.Input
            className={classNames(
              'w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0',
              'disabled:cursor-not-allowed disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-500',
              { 'pl-9': icon }
            )}
            // @ts-ignore
            displayValue={(i) => i?.name}
            onChange={(e) => setQuery(e.target.value)}
            placeholder={placeholder}
          />
          <Cbox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
            <ChevronUpDownIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </Cbox.Button>
        </div>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          afterLeave={() => setQuery('')}
        >
          <Cbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base z-10 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {filteredItems.length === 0 && query !== '' ? (
              <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                Nothing found.
              </div>
            ) : (
              filteredItems.map((i) => (
                <Cbox.Option
                  key={i.id}
                  className={({ active }) =>
                    `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-indigo-600 text-white' : 'text-gray-900'} hover:cursor-pointer`
                  }
                  value={i}
                >
                  {({ selected, active }) => (
                    <>
                      <span className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}>
                        {i.name}
                      </span>
                      {selected ? (
                        <span
                          className={`absolute inset-y-0 left-0 flex items-center pl-3 ${active ? 'text-white' : 'text-teal-600'
                            }`}
                        >
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </Cbox.Option>
              ))
            )}
          </Cbox.Options>
        </Transition>
      </div>
    </Cbox>
  )
}
