import { useState, useEffect } from 'react'
import { TrashIcon } from '@heroicons/react/24/outline'
import Skeleton from 'react-loading-skeleton'
import { useQuery, useMutation } from '@apollo/client'
import { toast } from 'react-toastify'
import { useGlobalStore } from '../../../../store'
import { Input, PhoneNumberInput, Switch, Button, Modal } from '../../../../components/elements'
import { GET_SINGLE_USER, EDIT_USER, DELETE_USER } from '../../../../graphql'
import { EditUserInput } from '../../../../types/gql/graphql'

type EditUserDetailsProps = {
  id: string
  mobileNumber: string
  isOrgAdmin: boolean
  onUpdated: (fullName: string, isOrgAdmin: boolean) => void
  onDelete: (id: string) => void
}

export function EditUserDetails({ id, mobileNumber, isOrgAdmin, onUpdated, onDelete }: EditUserDetailsProps) {
  // Hooks
  const authenticatedUserInfo = useGlobalStore(s => s.authenticatedUserInfo)
  const { loading, error, data } = useQuery(GET_SINGLE_USER, {
    variables: {
      getUserId: id,
    },
    fetchPolicy: 'network-only'
  })
  const [editUser, { loading: editLoading }] = useMutation(EDIT_USER)
  const [deleteUser, { loading: deleteLoading }] = useMutation(DELETE_USER)

  // Component state
  const [firstName, setFirstName] = useState<string>('')
  const [lastName, setLastName] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [jobTitle, setJobTitle] = useState<string>('')
  const [isOrgAdminVal, setIsOrgAdminVal] = useState<boolean>(isOrgAdmin)

  // Handlers
  const handleUpdate = async (): Promise<boolean> => {
    if (!data?.getUser) {
      toast.info('Cannot verify the user\'s details. Please try again or contact support.')
      return Promise.resolve(false)
    }

    // Extract the fields that have been updated
    const updatedFields: EditUserInput = {}
    if (firstName && firstName !== data?.getUser.firstName) {
      updatedFields.firstName = firstName
    }

    if (lastName && lastName !== data?.getUser.lastName) {
      updatedFields.lastName = lastName
    }

    if (email && email !== data?.getUser.email) {
      updatedFields.email = email
    }

    if (jobTitle && jobTitle !== data?.getUser.jobTitle) {
      updatedFields.jobTitle = jobTitle
    }

    if (Object.keys(updatedFields).length === 0) {
      toast.info('You did not make any changes to the user.')
      return Promise.resolve(false)
    }

    try {
      const response = await editUser({
        variables: {
          userId: id,
          input: updatedFields,
        }
      })

      if (response.errors || !response.data?.editUser) {
        toast.error('Unable to update the user. Please try again or contact support.')
        return Promise.resolve(false)
      }

      toast.success('User account updated successfully.')

      return Promise.resolve(true)
    } catch (error) {
      toast.error('Unable to update the user. Please try again or contact support.')
      return Promise.resolve(false)
    }
  }

  const toggleOrgAdminAccess = async (isAdmin: boolean): Promise<boolean> => {
    try {
      const response = await editUser({
        variables: {
          userId: id,
          input: {
            isAdmin,
          },
        }
      })

      if (response.errors || !response.data?.editUser) {
        toast.error('Unable to update the user\'s permissions. Please try again or contact support.')
        return Promise.resolve(false)
      }

      toast.success(isAdmin ? 'User has been granted admin access.' : 'Admin access revoked.')
      onUpdated(`${firstName} ${lastName}`, isAdmin)

      return Promise.resolve(true)
    } catch (error) {
      toast.error('Unable to update the user\'s permissions. Please try again or contact support.')
      return Promise.resolve(false)
    }
  }

  const handleDelete = async (): Promise<boolean> => {
    try {
      const response = await deleteUser({
        variables: {
          userId: id,
        }
      })

      if (response.errors || !response.data?.deleteUser) {
        toast.error('Unable to delete the user. Please try again or contact support.')
        return Promise.resolve(false)
      }

      toast.success('User account deleted successfully. They will no longer be able to access Streamline.')

      return Promise.resolve(true)
    } catch (error) {
      toast.error('Unable to delete the user. Please try again or contact support.')
      return Promise.resolve(false)
    }
  }

  // Watchers
  useEffect(() => {
    if (!error && !loading && data && data.getUser) {
      setFirstName(data.getUser.firstName)
      setLastName(data.getUser.lastName)
      setEmail(data.getUser.email || '')
      setJobTitle(data.getUser.jobTitle || '')
    }
  }, [data, error, loading])

  return (
    <>
      {loading ? (
        <div className="flex flex-col gap-6 my-6">
          <div className="flex flex-col gap-4 sm:flex-row">
            <div className="flex-grow">
              <Skeleton className="w-full h-9" />
            </div>
            <div className="flex-grow">
              <Skeleton className="w-full h-9" />
            </div>
          </div>
          <div className="flex flex-col gap-4 sm:flex-row">
            <div className="sm:w-1/2">
              <Skeleton className="w-full h-9" />
            </div>
            <div className="sm:w-1/2">
              <Skeleton className="w-full h-9" />
            </div>
          </div>
          <div className="pr-2 sm:w-1/2">
            <Skeleton className="w-full h-9" />
          </div>
          <Skeleton className="w-full h-24" />

          <div className="flex gap-2">
            <Skeleton className="w-32 h-9" />
            <Skeleton className="w-14 h-9" />
          </div>
        </div>
      ) : (
        <div className="flex flex-col gap-6 my-6">
          <div className="flex flex-col gap-4 sm:flex-row">
            <div className="flex-grow">
              <Input
                label="First name"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                required
              />
            </div>
            <div className="flex-grow">
              <Input
                label="Last name"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                required
              />
            </div>
          </div>
          <div className="flex flex-col gap-4 sm:flex-row">
            <div className="sm:w-1/2">
              <PhoneNumberInput
                label="Phone number"
                disabled
                value={mobileNumber}
                onChange={() => { }}
              />
            </div>
            <div className="sm:w-1/2">
              <Input
                type="email"
                label="Email"
                hint="Optional"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
          </div>
          <div className="pr-2 sm:w-1/2">
            <Input
              label="Job title"
              hint="Optional"
              value={jobTitle}
              onChange={(e) => setJobTitle(e.target.value)}
            />
          </div>
          <div className="flex gap-4 items-center justify-between p-4 bg-gray-50 rounded-lg">
            <div className="flex flex-col gap-1 text-sm">
              <span className="font-medium">Grant Admin Module access?</span>
              <p className="text-xs text-gray-600">
                If you grant admin access, this user will be able to access the Admin Module to create/edit groups,
                users, etc. and modify organisation settings. You can revoke admin access at any time.
              </p>
            </div>
            <Switch
              checked={isOrgAdminVal}
              onChange={(selected) => {
                setIsOrgAdminVal(selected)
                toggleOrgAdminAccess(selected)
              }}
            />
          </div>

          <div className="flex gap-2">
            <Button
              type="button"
              text="Save changes"
              loading={editLoading}
              onClick={async () => {
                const isUpdated = await handleUpdate()
                if (isUpdated) {
                  onUpdated(`${firstName} ${lastName}`, isOrgAdminVal)
                }
              }}
            />
            {id === authenticatedUserInfo?.getAuthenticatedUser.id ? (
              <Button
                variant="outline"
                disabled
                icon={{
                  element: <TrashIcon />,
                  position: 'center'
                }}
              />
            ) : (
              <Modal
                variant="error"
                trigger={(
                  <Button
                    variant="outline"
                    icon={{
                      element: <TrashIcon />,
                      position: 'center'
                    }}
                  />
                )}
                title={`Delete ${data?.getUser.firstName} ${data?.getUser.lastName}?`}
                description="Tasks already assigned to this user will be unaffected by this change."
                primaryButton={{
                  text: 'Delete user',
                  loading: deleteLoading,
                  onClick: async (closeModal) => {
                    const isRemoved = await handleDelete()
                    if (isRemoved) {
                      closeModal()
                      onDelete(id)
                    }
                  }
                }}
              />
            )}
          </div>
        </div>
      )}
    </>
  )
}
