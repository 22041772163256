import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import { isAfter, addMinutes } from 'date-fns'
import { createHttpLink, ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import { setContext } from '@apollo/client/link/context'
import 'react-loading-skeleton/dist/skeleton.css'
import 'react-datepicker/dist/react-datepicker.css';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Routes } from './types';
import { firebaseAuth } from './firebase';

// Initialise Apollo client
const httpLink = createHttpLink({
  uri: `${process.env.REACT_APP_SERVER_URL}/graphql`,
});

const authLink = setContext(async (_, { headers }) => {
  const idToken = localStorage.getItem('firebaseIdToken');
  const idTokenExpiration = localStorage.getItem('firebaseIdTokenExpiration');

  // If the current time is after the expiration time, renew the token by calling
  // firebaseAuth.currentUser.getIdToken(true) and save the new token to localstorage
  if (idTokenExpiration && isAfter(new Date(), new Date(idTokenExpiration))) {
    try {
      const newToken = await firebaseAuth.currentUser?.getIdToken(true)
      if (newToken) {
        localStorage.setItem('firebaseIdToken', newToken)
        localStorage.setItem('firebaseIdTokenExpiration', addMinutes(new Date(), 50).toISOString())

        return {
          headers: {
            ...headers,
            authorization: idToken || '',
          }
        }
      }

      window.location.href = Routes.LogIn
    } catch (error) {
      window.location.href = Routes.LogIn
    }
  } else {
    return {
      headers: {
        ...headers,
        authorization: idToken || '',
      }
    }
  }
});

const apolloClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

// Render the application
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ApolloProvider client={apolloClient}>
        <App />
      </ApolloProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
