import { useState } from 'react'
import classNames from 'classnames'
import { isToday, isTomorrow, isAfter, format, formatRelative } from 'date-fns';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { CheckCircleIcon as CheckCircleOutline, AtSymbolIcon } from '@heroicons/react/24/outline'
import { CheckCircleIcon as CheckCircleSolid, UserGroupIcon, SquaresPlusIcon } from '@heroicons/react/24/solid'
import { Modal } from '../../../components/elements'
import { IdName } from '../../../types/gql/graphql';
import { MARK_TASK_AS_COMPLETED, REOPEN_TASK } from '../../../graphql'
import { Textarea } from '../../../components/elements';

type DueDatePillProps = {
  due: string
  isCompleted: boolean
}

function DueDatePill({ due, isCompleted }: DueDatePillProps) {
  const current = new Date()
  const dueDate = new Date(parseInt(due))

  // Construct the relative date string
  let relativeDate = formatRelative(dueDate, current).replace('at 11:59 PM', '')

  // If the relativeDate contains "/", format it with the full date and time
  if (relativeDate.includes('/')) {
    relativeDate = format(dueDate, 'MMM do, yyyy - hh:mm aa').replace('-', 'at').replace('at 11:59 PM', '')
  }

  // If the first letter of the relativeDate is lowercase, capitalise it
  if (relativeDate[0] === relativeDate[0].toLowerCase()) {
    relativeDate = relativeDate[0].toUpperCase() + relativeDate.slice(1)
  }

  return (
    <div className={classNames(
      'py-1 px-2 rounded bg-gray-100 w-fit',
      { 'bg-red-200 text-red-700': (isToday(dueDate) && !isCompleted) || (isAfter(current, dueDate) && !isCompleted) },
      { 'bg-orange-100 text-orange-500': isTomorrow(dueDate) && !isCompleted }
    )}>
      <span>{relativeDate}</span>
    </div>
  )
}

type TaskProps = {
  id: string
  isCompleted: boolean
  title: string
  description?: string | null
  due?: string | null
  assignedTo?: IdName | null
  group?: IdName | null
  authenticatedUserId: string
  createdBy?: IdName | null
  onCompleted: (id: string) => void
  onReopened: (id: string) => void
  onClick: (id: string) => void
}

export function Task({
  id, isCompleted, title, description, due, assignedTo, group, authenticatedUserId, createdBy,
  onCompleted, onReopened, onClick
}: TaskProps) {
  // Hooks
  const [markTaskAsCompleted, { loading: markAsCompletedLoading }] = useMutation(MARK_TASK_AS_COMPLETED)
  const [reopenTask, { loading: reopenLoading }] = useMutation(REOPEN_TASK)

  // Component state
  const [completed, setCompleted] = useState<boolean>(isCompleted)
  const [comment, setComment] = useState<string>('')

  const markAsCompleted = async (): Promise<boolean> => {
    setCompleted(true)

    try {
      const response = await markTaskAsCompleted({
        variables: {
          id,
          ...(comment && { comment })
        }
      })

      if (response.errors) {
        toast.error('An error occurred while marking the task as completed. Please try again or contact support.')
        setCompleted(false)
        return Promise.resolve(false)
      }

      if (response.data?.markTaskAsCompleted) {
        return Promise.resolve(true)
      }

      return Promise.resolve(false)
    } catch (error) {
      toast.error('An error occurred while marking the task as completed. Please try again or contact support.')
      setCompleted(false)
      return Promise.resolve(false)
    }
  }

  const reopenCompletedTask = async (): Promise<boolean> => {
    setCompleted(true)

    try {
      const response = await reopenTask({
        variables: {
          id
        }
      })

      if (response.errors || !response.data?.reopenTask) {
        toast.error('An error occurred while attempting to re-open the task. Please try again or contact support.')
        return Promise.resolve(false)
      }

      return Promise.resolve(true)
    } catch (error) {
      toast.error('An error occurred while attempting to re-open the task. Please try again or contact support.')
      return Promise.resolve(false)
    }
  }

  return (
    <div
      className={classNames(
        'flex gap-4 items-start p-4 border border-gray-200',
        'bg-white hover:bg-gray-50',
        'hover:cursor-pointer w-full md:max-w-[90%] lg:max-w-[75%] rounded-l shadow-sm'
      )}
      onClick={() => { onClick(id) }}
    >
      <>
        {!isCompleted && !completed && (
          <Modal
            trigger={<CheckCircleOutline className="text-gray-400 hover:text-indigo-600 w-7 h-7" />}
            title="Add a comment"
            description="You can add an optional comment to this task before marking it as completed. Your comment will be visible to all subscribers of this task."
            primaryButton={{
              text: 'Submit',
              loading: markAsCompletedLoading,
              onClick: async function (close) {
                const completed = await markAsCompleted()
                if (completed) {
                  toast.success('Task completed!')
                  close()
                  onCompleted(id)
                }
              }
            }}
          >
            <div className="mt-4">
              <Textarea
                label="Comment"
                hint="Optional"
                value={comment}
                onChange={(e) => { setComment(e.target.value) }}
              />
            </div>
          </Modal>
        )}

        {!isCompleted && completed && (
          <CheckCircleSolid className="text-green-500 w-7 h-7" />
        )}

        {isCompleted && (
          <Modal
            trigger={<CheckCircleSolid className="text-gray-500 w-7 h-7" />}
            title="Re-open task?"
            description="Are you sure you want to re-open this task? This action will remove it from the completed tasks list."
            primaryButton={{
              text: 'Re-open',
              loading: reopenLoading,
              onClick: async function (close) {
                const reopened = await reopenCompletedTask()
                if (reopened) {
                  close()
                  onReopened(id)
                  toast.success('Task re-opened!')
                }
              }
            }}
          />
        )}
      </>
      <div className="flex flex-col gap-1 overflow-hidden">
        <span className="font-medium">{title}</span>
        {description && <span className="text-sm text-gray-500 truncate">{description}</span>}

        <div className="flex flex-col lg:flex-row gap-2 lg:gap-5 mt-3 text-xs text-gray-500">
          {due && <DueDatePill due={due} isCompleted={isCompleted} />}

          {assignedTo && (
            <div className="flex gap-1 items-center">
              <AtSymbolIcon className="text-gray-600 w-4 h-4" />
              {assignedTo.name}
            </div>
          )}

          {group && (
            <div className="flex gap-1 items-center">
              <UserGroupIcon className="text-gray-600 w-4 h-4" />
              {group.name}
            </div>
          )}

          {authenticatedUserId && createdBy && (createdBy.id !== assignedTo?.id) && (
            <div className="flex gap-1 items-center">
              <SquaresPlusIcon className="text-gray-600 w-4 h-4" />
              Assigned by {createdBy.id !== authenticatedUserId ? createdBy.name : 'Me'}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
