import classNames from "classnames";
import {
  PencilIcon, PlusIcon, CalendarIcon, CheckIcon, ArrowUturnLeftIcon, TrashIcon, UserIcon
} from '@heroicons/react/24/outline'
import { TaskActivityLogType, TaskActivityLog } from "../../../types/gql/graphql";
import { format } from "date-fns";

// Local helper functions
const getIconForLogType = (type: TaskActivityLogType): JSX.Element => {
  switch (type) {
    case TaskActivityLogType.Completed:
      return <CheckIcon className="w-4 h-4 text-white" />

    case TaskActivityLogType.Created:
      return <PlusIcon className="w-4 h-4 text-white" />

    case TaskActivityLogType.Datechanged:
      return <CalendarIcon className="w-4 h-4 text-white" />

    case TaskActivityLogType.Deleted:
      return <TrashIcon className="w-4 h-4 text-white" />

    case TaskActivityLogType.Edited:
      return <PencilIcon className="w-4 h-4 text-white" />

    case TaskActivityLogType.Reassigned:
      return <UserIcon className="w-4 h-4 text-white" />

    case TaskActivityLogType.Reopened:
      return <ArrowUturnLeftIcon className="w-4 h-4 text-white" />

    default:
      return <></>
  }
}

const getDescriptionForLogType = (type: TaskActivityLogType): string => {
  switch (type) {
    case TaskActivityLogType.Completed:
      return 'Task completed'

    case TaskActivityLogType.Created:
      return 'Created'

    case TaskActivityLogType.Datechanged:
      return 'Due date changed'

    case TaskActivityLogType.Deleted:
      return 'Task deleted'

    case TaskActivityLogType.Edited:
      return 'Edited'

    case TaskActivityLogType.Reassigned:
      return 'Reassigned'

    case TaskActivityLogType.Reopened:
      return 'Task reopened'

    default:
      return 'Unknown'
  }
}

const getFormattedLogDate = (date: string): string => {
  return format(new Date(date), 'MMM do, yyyy - h:mm a')
}

// Local types
type TaskHistoryTabProps = {
  log: Array<TaskActivityLog>
}

export function TaskHistoryTab({ log }: TaskHistoryTabProps) {
  return (
    <ul className="mt-4">
      {log.map((l, eventIdx) => (
        <li key={l.id}>
          <div className="relative pb-8">
            {eventIdx !== log.length - 1 ? (
              <span className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />
            ) : null}
            <div className="relative flex space-x-3">
              <div>
                <span
                  className={classNames(
                    'bg-gray-500 h-8 w-8 rounded-full flex items-center justify-center',
                    { 'bg-red-500': l.type === TaskActivityLogType.Deleted },
                    { 'bg-indigo-500': l.type === TaskActivityLogType.Reopened },
                    { 'bg-green-500': l.type === TaskActivityLogType.Completed },
                  )}
                >
                  {getIconForLogType(l.type)}
                </span>
              </div>
              <div className="flex flex-col sm:flex-row gap-2 sm:gap-0 min-w-0 flex-1 sm:justify-between sm:space-x-4 pt-1.5">
                <div>
                  <p className="text-sm text-gray-500">
                    {getDescriptionForLogType(l.type)}{' by '}
                    <span className="font-medium text-gray-900">
                      {l.actor}
                    </span>
                  </p>
                  {l.note && (
                    <p className="mt-1 text-xs text-gray-500">
                      {l.note}
                    </p>
                  )}
                </div>
                <div className="whitespace-nowrap sm:text-right text-sm text-gray-500">
                  <time dateTime={l.when}>{getFormattedLogDate(l.when)}</time>
                </div>
              </div>
            </div>
          </div>
        </li>
      ))}
    </ul>
  )
}
