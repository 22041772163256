import { useState, useEffect } from 'react'
import { UsersIcon } from '@heroicons/react/24/solid'
import { useLazyQuery } from '@apollo/client'
import { toast } from 'react-toastify'
import { Select } from '../../../components/elements'
import { IdName } from '../../../types/gql/graphql'
import { LIST_GROUPS_FOR_USER } from '../../../graphql'

// Local variables
export const noGroup: IdName = { id: 'none', name: 'No group' }
const loadingGroup: IdName = { id: 'loading', name: 'Fetching groups...' }

// Local types
type GroupSelectProps = {
  userId: string
  value?: IdName
  disabled?: boolean
  onChange: (group: IdName) => void
}

export function GroupSelect({ userId, value, disabled = false, onChange }: GroupSelectProps) {
  // Hooks
  const [listGroupsForUser, { loading }] = useLazyQuery(LIST_GROUPS_FOR_USER);

  // Component state
  const [selectedGroup, setSelectedGroup] = useState<IdName>(value || noGroup)
  const [groups, setGroups] = useState<IdName[]>([loadingGroup])

  // Get groups for the selected user. Prepend the 'no group' option.
  const getGroups = async () => {
    try {
      const response = await listGroupsForUser({
        variables: {
          input: {
            userId,
            returnAll: true,
          },
        }
      });

      if (response.error || !response.data?.listGroupsForUser) {
        toast.error('Unable to get a list of groups for the selected user. You can assign this task only to the user. It will not be listed under the group.')
        setGroups([noGroup]);
        return;
      }

      // Transform the response to an array of IdName
      const transformedGroups: IdName[] = response.data.listGroupsForUser.groups.map((group: any) => {
        return {
          id: group.id,
          name: group.name,
        }
      });

      setGroups([...[noGroup], ...transformedGroups]);

      // Set the selected item based on the response
      if (response.data?.listGroupsForUser.groups.length === 0) {
        setSelectedGroup(noGroup);
        onChange(noGroup);
        toast.info('This user does not have access to any group. This task will be directly assigned to the user and will not appear under any group filter.')
        return
      }

      if (!disabled && response.data?.listGroupsForUser.groups.length > 0 && !value) {
        setSelectedGroup(transformedGroups[0]);
        onChange(transformedGroups[0]);
        return
      }

      if (value && value.id !== selectedGroup.id && transformedGroups.find((g) => g.id === value.id)) {
        setSelectedGroup(value)
        onChange(value)
        return
      }
    } catch (error) {
      toast.error('Unable to get a list of groups for the selected user. You can assign this task only to the user. It will not be listed under the group.')
      setGroups([noGroup]);
    }
  }

  // Watchers
  useEffect(() => {
    if (userId) {
      getGroups()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (userId) {
      getGroups();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId])

  useEffect(() => {
    if (value && value.id !== selectedGroup.id) {
      setSelectedGroup(value)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  useEffect(() => {
    if (loading) {
      setGroups([loadingGroup])
    }
  }, [loading])

  return (
    <Select
      label="Group"
      icon={<UsersIcon />}
      value={selectedGroup}
      items={groups}
      disabled={disabled || loading || groups.length === 0 || groups.length === 1}
      onChange={(i) => { onChange({ id: `${i.id}`, name: i.name }) }}
    />
  )
}
