import { graphql } from '../types/gql'

export const LIST_TASKS = graphql(`
  query listTasks($input: ListTaskInput!) {
    listTasks(input: $input) {
      id
      title
      description
      dueDate
      assignedTo {
        id
        name
      }
      group {
        id
        name
      }
      createdBy {
        id
        name
      }
    }
  }
`)
