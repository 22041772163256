import { useEffect } from 'react'
import { UserIcon } from '@heroicons/react/24/solid'
import { useLazyQuery } from '@apollo/client'
import { Combobox } from '../../../components/elements'
import { useGlobalStore, useTaskStore } from '../../../store'
import { IdName } from '../../../types/gql/graphql'
import { LIST_ASSIGNABLE_USERS } from '../../../graphql'
import { toast } from 'react-toastify'

type UserSelecProps = {
  label?: string
  value?: IdName
  disabled?: boolean
  onChange: (user: IdName) => void
}

export function UserSelect({ label = 'Assign to', value, disabled = false, onChange }: UserSelecProps) {
  // Hooks
  const [listAssignableUsers, { loading }] = useLazyQuery(LIST_ASSIGNABLE_USERS, { fetchPolicy: 'network-only' })

  // Store
  const authenticatedUserInfo = useGlobalStore(state => state.authenticatedUserInfo)
  const authenticatedUserFullName = useGlobalStore(state => state.authenticatedUserFullName)
  const assignableUsers = useTaskStore(state => state.assignableUsers)
  const setAssignableUsers = useTaskStore(state => state.setAssignableUsers)

  // Component state
  const authenticatedUserItem: IdName = {
    id: authenticatedUserInfo?.getAuthenticatedUser.id || '',
    name: authenticatedUserFullName || '',
  }

  // Get users and save it to the store
  const getUsers = async () => {
    try {
      const response = await listAssignableUsers()

      if (response.error || !response.data?.listAssignableUsers) {
        toast.warn('Unable to get a list of users. You can only assign tasks to yourself until you reload the page and try again.')
        setAssignableUsers([authenticatedUserItem])
        return
      }

      setAssignableUsers(response.data.listAssignableUsers)
    } catch (error) {
      toast.warn('Unable to get a list of users. You can only assign tasks to yourself until you reload the page and try again.')
      setAssignableUsers([authenticatedUserItem])
    }
  }

  // Watchers
  useEffect(() => {
    getUsers()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Combobox
      label={label}
      icon={<UserIcon />}
      placeholder={!assignableUsers && loading ? 'Fetching users...' : 'Select a user'}
      items={assignableUsers}
      value={value || authenticatedUserItem}
      disabled={disabled || loading}
      onChange={(i) => {
        onChange({ id: `${i.id}`, name: i.name })
      }}
    />
  )
}
