import { graphql } from '../types/gql'

export const EDIT_TASK = graphql(`
  mutation editTask($editTaskId: String!, $input: CreateTaskInput!) {
    editTask(id: $editTaskId, input: $input) {
      id
      title
      description
      assignedTo {
        id
        name
      }
      group {
        id
        name
      }
      dueDate
    }
  }
`)
