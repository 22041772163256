import classNames from "classnames"

type TextareaProps = React.DetailedHTMLProps<React.TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement> & {
  label?: string
  hint?: string
}

export function Textarea({
  rows = 4,
  label,
  hint,
  ...props
}: TextareaProps) {
  return (
    <div>
      {(label || hint) && (
        <div className="flex justify-between">
          <label htmlFor="textarea" className="block text-sm font-medium text-gray-700">
            {label}
          </label>
          <span className="text-sm text-gray-500" id="email-optional">
            {hint}
          </span>
        </div>
      )}
      <div className={classNames({ 'mt-1': label || hint })}>
        <textarea
          rows={rows}
          name="textarea"
          className={classNames(
            'block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm',
            'disabled:cursor-not-allowed disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-500'
          )}
          {...props}
        />
      </div>
    </div>
  )
}