import ReactPaginate, { ReactPaginateProps } from 'react-paginate'
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/solid'

export function Pagination({
  pageRangeDisplayed = 5,
  ...props
}: ReactPaginateProps) {
  return (
    <ReactPaginate
      containerClassName="isolate inline-flex -space-x-px rounded-md shadow-sm"
      previousClassName="relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
      pageClassName="relative hidden items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20 md:inline-flex"
      activeClassName="relative z-10 inline-flex items-center border border-indigo-500 bg-indigo-50 px-4 py-2 text-sm font-medium text-indigo-600 focus:z-20"
      breakClassName="relative inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700"
      nextClassName="relative inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
      previousLabel={<ChevronLeftIcon className="h-5 w-5" />}
      previousAriaLabel="Previous"
      nextLabel={<ChevronRightIcon className="h-5 w-5" />}
      nextAriaLabel="Next"
      {...props}
    />
  )
}