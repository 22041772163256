import { useState, useEffect } from 'react'
import { TrashIcon } from '@heroicons/react/24/solid'
import Skeleton from 'react-loading-skeleton'
import { useQuery, useMutation } from '@apollo/client'
import { toast } from 'react-toastify'
import { Button, Switch, Modal } from '../../../../components/elements'
import { LIST_GROUPS_FOR_USER, REMOVE_USER_FROM_GROUP, GRANT_USER_ROLE_FOR_GROUP } from '../../../../graphql'
import { ListGroupsForUserItem, PermissionRole } from '../../../../types/gql/graphql'

// Local types
type EditUserGroupsProps = {
  id: string
  onGroupsChanged: (groups: string[], userCount: number) => void
}

type GroupItem = ListGroupsForUserItem | null

export function EditUserGroups({ id, onGroupsChanged }: EditUserGroupsProps) {
  // Component state
  const [groupDataFetched, setGroupDataFetched] = useState<boolean>(false)
  const [groups, setGroups] = useState<GroupItem[]>([])

  // Hooks
  const { loading, error, data } = useQuery(LIST_GROUPS_FOR_USER, {
    variables: {
      input: {
        userId: id,
        returnAll: true
      }
    },
    fetchPolicy: 'network-only'
  })
  const [removeUserFromGroup, { loading: removeUserLoading }] = useMutation(REMOVE_USER_FROM_GROUP)
  const [grantUserRoleForGroup] = useMutation(GRANT_USER_ROLE_FOR_GROUP)

  // Watchers
  useEffect(() => {
    if (!error && !loading && data && data.listGroupsForUser) {
      setGroups(data.listGroupsForUser.groups)
      setGroupDataFetched(true)
    }

    if (groupDataFetched) {
      const groupNames = groups.map(g => g?.name || '')
      onGroupsChanged(groupNames, groups.length)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  // Handlers
  const handleRemoveUserFromGroup = async (groupId: string): Promise<boolean> => {
    try {
      const response = await removeUserFromGroup({
        variables: {
          groupId,
          userId: id
        }
      })

      if (response.errors || !response.data?.removeUserFromGroup) {
        toast.error('Unable to remove the user from the group. Please try again or contact support.')
        return Promise.resolve(false)
      }

      // Remove the group from the groups list
      const updatedGroups = groups.filter(g => g?.id !== groupId)
      setGroups(updatedGroups)

      toast.success('User removed from group')

      return Promise.resolve(true)
    } catch (error) {
      toast.error('Unable to remove the user from the group. Please try again or contact support.')
      return Promise.resolve(false)
    }
  }

  const updateUserRole = (groupId: string, isAdmin: boolean) => {
    const groupIndex = groups.findIndex(g => g?.id === groupId)
    if (groupIndex < 0 || !groups[groupIndex]) {
      return
    }

    const updatedGroups = [...groups]
    const group = updatedGroups[groupIndex]
    if (group) {
      group.isAdmin = isAdmin
      setGroups(updatedGroups)
    }
  }

  const handleRoleToggle = async (groupId: string, isAdmin: boolean) => {
    const groupIndex = groups.findIndex(g => g?.id === groupId)
    if (!groups[groupIndex] || groups[groupIndex]?.isAdmin === isAdmin) {
      return
    }
    updateUserRole(groupId, isAdmin)

    try {
      const response = await grantUserRoleForGroup({
        variables: {
          groupId,
          userId: id,
          role: isAdmin ? PermissionRole.Admin : PermissionRole.Member
        }
      })

      if (response.errors || !response.data) {
        toast.error('Unable to update the user\'s role. Please try again or contact support.')
        updateUserRole(groupId, !isAdmin)
        return
      }

      toast.success(isAdmin ? 'Admin access granted' : 'Admin access revoked')
    } catch (error) {
      toast.error('Unable to update the user\'s role. Please try again or contact support.')
      updateUserRole(groupId, !isAdmin)
    }
  }

  return (
    <div className="flex flex-col gap-8">
      {/* {loading ? (
        <div className="flex items-end gap-4">
          <Skeleton className="w-[421px] h-10" />
          <Skeleton className="w-[108px] h-10" />
          <Skeleton className="w-16 h-10" />
        </div>
      ) : (
        <div className="flex items-end gap-4">
          <div className="flex-grow">
            <Input
              label="Add user to group"
              placeholder="Search group"
              icon={{
                element: <UsersIcon />,
                position: 'left'
              }}
            />
          </div>
          <Select
            items={[
              { id: 'member', name: 'Member' },
              { id: 'admin', name: 'Admin' }
            ]}
            onChange={() => { }}
          />
          <Button text="Add" />
        </div>
      )} */}

      <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
        <table className="min-w-full divide-y divide-gray-300">
          <thead className="bg-gray-50">
            <tr>
              <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                Name
              </th>
              <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                Admin Access
              </th>
              <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                <span className="sr-only">Remove from group</span>
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            {loading && [1, 2, 3, 4, 5].map((i) => (
              <tr key={i}>
                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                  <Skeleton className="w-full h-8" />
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                  <Skeleton className="w-full h-8" />
                </td>
                <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                  <Skeleton className="w-full h-8" />
                </td>
              </tr>
            ))}

            {!loading && !error && groups.length === 0 && (
              <tr>
                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-6">
                  This user does not have access to any group
                </td>
              </tr>
            )}

            {groups.map((g) => {
              if (!g) {
                return null
              }

              return (
                <tr key={g.id}>
                  <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                    {g.name}
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    <Switch checked={g.isAdmin} onChange={(v) => { handleRoleToggle(g.id, v) }} />
                  </td>
                  <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                    <Modal
                      variant="error"
                      trigger={(
                        <Button
                          variant="outline"
                          size="sm"
                          icon={{
                            element: <TrashIcon />,
                            position: 'center',
                          }}
                          onClick={() => { }}
                        />
                      )}
                      title="Remove user from group?"
                      description={`Tasks already assigned to this user in ${g.name} will be unaffected by this change.`}
                      primaryButton={{
                        text: 'Remove from group',
                        loading: removeUserLoading,
                        onClick: async (closeModal) => {
                          const isRemoved = await handleRemoveUserFromGroup(g.id)
                          if (isRemoved) {
                            closeModal()
                          }
                        }
                      }}
                    />
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}
