import { Fragment, useState, useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { useLazyQuery } from '@apollo/client'
import { toast } from 'react-toastify'
import { Tabs } from '../../../components/elements'
import { EditTaskTab } from './EditTaskTab'
import { TaskHistoryTab } from './TaskHistoryTab'
import { TaskFormLoading } from './TaskFormLoading'
import { GET_SINGLE_TASK } from '../../../graphql'

// Local consts
const TAB_CONTAINER_CLASSNAMES = 'relative mt-6 flex-1 px-1 sm:px-6'

// Local types
type EditTaskModalProps = {
  taskId?: string
  onCompleted: (id: string) => void
  onReopened: (id: string) => void
  onDelete: (taskId: string) => void
  onClose: () => void
}

export function EditTaskModal({ taskId, onCompleted, onReopened, onDelete, onClose }: EditTaskModalProps) {
  // Hooks
  const [getSingleTask, { loading, error, data }] = useLazyQuery(GET_SINGLE_TASK)

  // Component state
  const [isOpen, setIsOpen] = useState<boolean>(taskId !== undefined)

  // Watchers
  useEffect(() => {
    setIsOpen(!!taskId)
    if (taskId) {
      getSingleTask({
        variables: { id: taskId },
        fetchPolicy: 'network-only'
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskId])

  useEffect(() => {
    if (error) {
      setIsOpen(false)
      toast.error('Error fetching task. Please try again or contact support if the issue persists.')
    }
  }, [error])

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => { setIsOpen(false); onClose(); }}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-2xl">
                  <div className="flex h-full flex-col overflow-y-scroll pt-4 pr-6 pb-6 pl-4 sm:p-0 bg-white shadow-xl">
                    {loading && (
                      <TaskFormLoading />
                    )}

                    {!loading && !error && data?.getSingleTask && (
                      <Tabs
                        tabs={{
                          'Task': {
                            element: (
                              <div className={TAB_CONTAINER_CLASSNAMES}>
                                <EditTaskTab
                                  task={data.getSingleTask}
                                  onCompleted={(id) => { onCompleted(id); onClose() }}
                                  onReopened={(id) => { onReopened(id); onClose() }}
                                  onDelete={(id) => { onDelete(id); onClose() }}
                                />
                              </div>)
                          },
                          // 'Comments': { element: <div className={TAB_CONTAINER_CLASSNAMES}><span>Comments</span></div> },
                          'History': { element: <div className={TAB_CONTAINER_CLASSNAMES}><TaskHistoryTab log={data.getSingleTask.activityLog} /></div> }
                        }}
                      />
                    )}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
