import { graphql } from '../types/gql'

export const LIST_ASSIGNABLE_USERS = graphql(`
  query listAssignableUsers {
    listAssignableUsers {
      id
      name
    }
  }
`)
