import classNames from 'classnames'

type InputProps = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & {
  label?: string
  hint?: string
  icon?: {
    element: JSX.Element,
    position: 'left' | 'right'
  }
  helpText?: string
}

export function Input({
  type = "text",
  label,
  hint,
  icon,
  helpText,
  ...props
}: InputProps) {
  return (
    <div className="w-full">
      {(label || hint) && (
        <div className="flex justify-between">
          <label htmlFor="email" className="block text-sm font-medium text-gray-700">
            {label}
          </label>
          <span className="text-sm text-gray-500" id="email-optional">
            {hint}
          </span>
        </div>
      )}
      <div
        className={classNames(
          'relative rounded-md shadow-sm',
          { 'mt-1': label || hint }
        )}
      >
        {icon && icon.position === 'left' && (
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3 text-gray-400 child:w-4 child:h-4">
            {icon.element}
          </div>
        )}
        <input
          type={type}
          className={classNames(
            'block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm',
            'disabled:cursor-not-allowed disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-500',
            { 'pl-9': icon && icon.position === 'left' },
            { 'pr-10': icon && icon.position === 'right' }
          )}
          {...props}
        />
        {icon && icon.position === 'right' && (
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3 text-gray-400 child:w-4 child:h-4">
            {icon.element}
          </div>
        )}
      </div>
      {helpText && (
        <p className="mt-2 text-sm text-gray-500" id="email-description">
          {helpText}
        </p>
      )}
    </div>
  )
}
