import { useEffect } from 'react'
import { Routes as RouterRoutes, Route, useLocation, useNavigate } from 'react-router-dom'
import { onAuthStateChanged } from 'firebase/auth'
import { RotatingLines } from 'react-loader-spinner'
import { useLazyQuery } from '@apollo/client'
import { isAfter, addMinutes } from 'date-fns'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { firebaseAuth } from './firebase'
import { useGlobalStore, useTaskStore } from './store'
import { Routes } from './types'
import { GET_AUTHENTICATED_USER_INFO } from './graphql'
import { LogInPage, AdminPage, ChecklistsPage, PnLPage, TasksPage } from './pages'

function App() {
  // Hooks
  const location = useLocation()
  const navigate = useNavigate()

  // Component state
  const appLoading = useGlobalStore(state => state.appLoading)
  const setAppLoaded = useGlobalStore(state => state.setAppLoaded)
  const authenticatedUserInfo = useGlobalStore(state => state.authenticatedUserInfo)
  const setAuthenticatedUserInfo = useGlobalStore(state => state.setAuthenticatedUserInfo)
  const setAuthenticatedUserId = useTaskStore(state => state.setAuthenticatedUserId)

  // Queries
  const [getAuthenticatedUserInfo, { loading, error, data }] = useLazyQuery(GET_AUTHENTICATED_USER_INFO)

  // Observers
  useEffect(() => {
    onAuthStateChanged(firebaseAuth, (user) => {
      if (user) {
        user.getIdToken().then((idToken) => {
          // Get the firebaseIDToken from localstorage and compare it with idToken
          if (
            localStorage.getItem('firebaseIdToken') !== idToken ||
            !localStorage.getItem('firebaseIdTokenExpiration') ||
            isAfter(new Date(), new Date(localStorage.getItem('firebaseIdTokenExpiration')!))
          ) {
            // Save ID token to localstorage since Apollo Client needs it
            localStorage.setItem('firebaseIdToken', idToken)

            // Set the expiration date of the token to 50 mins from now
            localStorage.setItem('firebaseIdTokenExpiration', addMinutes(new Date(), 50).toISOString())
          }

          // Fetch the authenticated user's information
          if (!authenticatedUserInfo) {
            getAuthenticatedUserInfo({
              fetchPolicy: 'network-only'
            })
          }

          // Redirect to the tasks page if the user is already logged in
          if (location.pathname === '/') {
            navigate(`${Routes.Tasks}?filter=ALL`)
          }
        })
      } else {
        // Transition to the loaded state
        setAppLoaded()

        // Redirect to the login page if the user visits the root URL
        if (location.pathname === '/') {
          navigate(Routes.LogIn)
        }
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!error && !loading && data) {
      if (appLoading) {
        setAppLoaded()
      }

      setAuthenticatedUserInfo(data)
      setAuthenticatedUserId(data.getAuthenticatedUser.id)

      if (location.pathname === Routes.LogIn) {
        navigate(`${Routes.Tasks}?filter=ALL`)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  if (appLoading) {
    return (
      <div className="app-root h-screen">
        <div className="flex flex-col justify-center items-center gap-4 w-screen h-screen">
          <RotatingLines
            strokeColor="grey"
            strokeWidth="4"
            animationDuration="0.75"
            width="40"
            visible={true}
          />

          <span className="text-gray-700 text-lg">Loading application..</span>
        </div>
      </div>
    )
  }

  return (
    <div className="app-root h-screen">
      <RouterRoutes>
        <Route path={Routes.LogIn} element={<LogInPage />} />
        <Route path={Routes.Checklists} element={<ChecklistsPage />} />
        <Route path={Routes.PnL} element={<PnLPage />} />
        <Route path={Routes.Tasks} element={<TasksPage />} />

        {authenticatedUserInfo?.getUserRolesSummary.isOrgAdmin && (
          <Route path={Routes.Admin} element={<AdminPage />} />
        )}
      </RouterRoutes>

      <ToastContainer />
    </div>
  )
}

export default App
