import { graphql } from '../types/gql'

export const GET_AUTHENTICATED_USER_INFO = graphql(`
  query getAuthenticatedUserInfo {
    getAuthenticatedUser {
      id
      isDisabled
      organisation
      firstName
      lastName
      phoneNumber
      email
      timezone
      jobTitle
    }
    getAuthenticatedUserOrganisation {
      id
      isDisabled
      displayName
      timezone
    }
    getUserRolesSummary {
      isOrgAdmin
      isGroupAdmin
    }
    listGroupsForUser {
      count
      groups {
        id
        name
        isAdmin
      }
    }
  }
`)
