import { UserIcon, UsersIcon, Cog6ToothIcon } from '@heroicons/react/24/solid'
import classNames from 'classnames';
import { Frame } from "../../components/partials";
import { useTitle } from "../../hooks";
import { Groups } from './admin/Groups'
import { Users } from './admin/Users'
import { Settings } from './admin/Settings'

// Local data
const adminMainNavItems = [
  { name: 'Groups', icon: UsersIcon },
  { name: 'Users', icon: UserIcon },
  { name: 'Settings', icon: Cog6ToothIcon },
]

export function AdminPage() {
  // Set page title
  useTitle('Admin')

  return (
    <Frame module="Admin">
      <section
        aria-labelledby="primary-heading"
        className="flex h-full min-w-0 flex-1 flex-col overflow-y-auto lg:order-last p-5"
      >
        <h1 id="primary-heading" className="sr-only">Admin</h1>

        <div className="flex flex-col gap-8">
          <Groups />
          <Users />
          <Settings />
        </div>
      </section>

      {/* Sub-navigation */}
      <aside className="hidden lg:order-first lg:block lg:flex-shrink-0">
        <div className="relative flex h-full w-80 flex-col overflow-y-auto border-r border-gray-200 p-5 bg-white">
          <nav className="space-y-1" aria-label="Sidebar">
            {adminMainNavItems.map((item) => (
              <button
                key={item.name}
                className={classNames(
                  'flex w-full',
                  'text-gray-600 hover:bg-gray-100 hover:text-gray-900',
                  'group flex items-center px-3 py-2 text-sm font-medium rounded-md'
                )}
                onClick={() => { }}
              >
                <item.icon
                  className={classNames(
                    'text-gray-400 group-hover:text-gray-500',
                    'flex-shrink-0 -ml-1 mr-3 h-6 w-6'
                  )}
                  aria-hidden="true"
                />
                <span className="truncate">{item.name}</span>
                {/* {item.count ? (
                  <span
                    className={classNames(
                      'bg-gray-100 text-gray-600 group-hover:bg-gray-200',
                      'ml-auto inline-block py-0.5 px-3 text-xs rounded-full'
                    )}
                  >
                    {item.count}
                  </span>
                ) : null} */}
              </button>
            ))}
          </nav>
        </div>
      </aside>
    </Frame>
  )
}
