import { WrenchScrewdriverIcon } from '@heroicons/react/24/outline'

export function UnderConstruction() {
  return (
    <div className="flex flex-col items-center gap-2 m-6 border-2 border-dashed border-gray-300 p-8 text-center rounded">
      <div className="flex items-center justify-center bg-yellow-500 text-yellow-800 w-20 h-20 rounded-full">
        <WrenchScrewdriverIcon className="w-10 h-10" />
      </div>
      <h1 className="mt-6 text-2xl font-semibold leading-none">Under Construction</h1>
      <p className="text-gray-500">This module is currently under construction. Please check back later.</p>
    </div>
  )
}
