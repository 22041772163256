import { useState, useEffect } from 'react'
import { PlusIcon, TrashIcon } from '@heroicons/react/24/solid'
import { Input, Textarea, DateTimePicker, Button } from '../../../components/elements'
import { CreateTaskInput, IdName, GetSingleTaskResponse } from '../../../types/gql/graphql'
import { UserSelect } from './UserSelect'
import { GroupSelect, noGroup } from './GroupSelect'
import { useGlobalStore } from '../../../store'

// Local types
enum DueDateFormat {
  DateOnly = 'MMM d, yyyy',
  Full = 'MMM d, yyyy - h:mm aa'
}

type TaskFormProps = {
  task?: GetSingleTaskResponse
  onChange: (task: CreateTaskInput) => void
}

export function TaskForm({ task, onChange }: TaskFormProps) {
  // Component state
  const authenticatedUserInfo = useGlobalStore(state => state.authenticatedUserInfo)
  const authenticatedUserFullName = useGlobalStore(state => state.authenticatedUserFullName)
  const isOrgAdminOrGroupAdmin = useGlobalStore(state => state.isOrgAdminOrGroupAdmin)
  const [dueDateFormat, setDueDateFormat] = useState<string>(DueDateFormat.DateOnly)
  const [title, setTitle] = useState<string>(task?.title || '')
  const [description, setDescription] = useState<string>(task?.description || '')
  const [subTasks, setSubTasks] = useState<string[]>(
    task?.subtasks?.map(subTask => subTask?.task || '') || ['']
  )
  const [assignedTo, setAssignedTo] = useState<IdName>()
  const [defaultGroup, setDefaultGroup] = useState<IdName>()
  const [group, setGroup] = useState<IdName>()
  const [dueDate, setDueDate] = useState<Date>()

  useEffect(() => {
    // Set the due date if supplied on load
    if (task?.dueDate) {
      const dueDate = new Date(parseInt(task.dueDate))
      setDueDate(dueDate)
    }

    // Set assignedTo if its undefined
    if (!task && !assignedTo) {
      setAssignedTo({
        id: authenticatedUserInfo?.getAuthenticatedUser.id || '',
        name: authenticatedUserFullName || '',
      })
    }

    // Set assignedTo if supplied
    if (task?.assignedTo) {
      setAssignedTo(task.assignedTo)
    }

    // Set group if supplied
    if (task?.group && task.group.id) {
      setDefaultGroup(task.group)
    }
    if (task && !task.group) {
      setDefaultGroup(noGroup)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // When any value changes, construct the create task input
  // and send it to the parent component
  useEffect(() => {
    const createTaskInput: CreateTaskInput = {
      title,
      description,
      assignedTo: assignedTo?.id || undefined,
      group: !group?.id || group?.id === 'none' ? undefined : group?.id,
      dueDate: dueDate?.toISOString(),
      subtasks: subTasks.filter(subTask => subTask !== ''),
    }

    if (task !== undefined) {
      // This is needed to avoid resetting subtasks when a task is edited
      delete createTaskInput.subtasks
    }

    onChange(createTaskInput)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [title, description, assignedTo, group, dueDate, subTasks])

  // Watch the due date. If the time is 12 AM, change the time to 11:59 PM.
  // This is to ensure that the due date is always set to the end of the day.
  useEffect(() => {
    if (!dueDate) {
      return
    }

    const hours = dueDate.getHours()
    const minutes = dueDate.getMinutes()

    if (hours === 0 && minutes === 0) {
      const newDueDate = new Date(dueDate)
      newDueDate.setHours(23)
      newDueDate.setMinutes(59)
      setDueDate(newDueDate)
    }
  }, [dueDate])

  // Watch the due date. If the time is set to 12:00 AM or 11:59 PM, then set
  // dueDateFormat to DateOnly. Otherwise, set it to Full.
  useEffect(() => {
    if (!dueDate) {
      setDueDateFormat(DueDateFormat.DateOnly)
      return
    }

    const hours = dueDate.getHours()
    const minutes = dueDate.getMinutes()

    if (hours === 0 && minutes === 0) {
      setDueDateFormat(DueDateFormat.DateOnly)
    } else if (hours === 23 && minutes === 59) {
      setDueDateFormat(DueDateFormat.DateOnly)
    } else {
      setDueDateFormat(DueDateFormat.Full)
    }
  }, [dueDate])

  return (
    <form className="flex flex-col gap-4 my-4 sm:gap-6 sm:my-6">
      <Input
        label="What is the task?"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        disabled={!!task?.completedAt}
        required
      />
      <Textarea
        label="Detailed description"
        hint="Optional"
        value={description}
        disabled={!!task?.completedAt}
        onChange={(e) => setDescription(e.target.value)}
      />
      <div>
        {subTasks.length < 1 ? (
          <Button
            size="sm"
            variant="outline"
            icon={{
              position: 'left',
              element: <PlusIcon />,
            }}
            text="Add sub-tasks"
            onClick={() => setSubTasks([''])}
          />
        ) : (
          <div className="flex flex-col gap-2">
            <h3 className="block text-sm font-medium text-gray-700">
              Sub-tasks {subTasks.length > 0 && `(${subTasks.length})`}
            </h3>

            {subTasks.map((t, index) => (
              <div className="flex gap-2 items-center">
                <Input
                  value={t}
                  onChange={(e) => {
                    const newSubTasks = [...subTasks]
                    newSubTasks[index] = e.target.value
                    setSubTasks(newSubTasks)
                  }}
                  onFocus={() => {
                    if (subTasks.length < 25 && index === subTasks.length - 1) {
                      setSubTasks([...subTasks, ''])
                    }
                  }}
                  disabled={task !== undefined}
                />
                <Button
                  variant="outline"
                  icon={{
                    position: 'center',
                    element: <TrashIcon />,
                  }}
                  tabIndex={-1}
                  onClick={() => {
                    const newSubTasks = [...subTasks]
                    newSubTasks.splice(index, 1)
                    setSubTasks(newSubTasks)
                  }}
                />
              </div>
            ))}
          </div>
        )}
      </div>
      {(isOrgAdminOrGroupAdmin || (authenticatedUserInfo?.listGroupsForUser && authenticatedUserInfo?.listGroupsForUser.count > 1)) && (
        <div className="flex flex-col gap-4 sm:flex-row">
          <div className="sm:w-1/2">
            <UserSelect
              value={task?.assignedTo || assignedTo}
              disabled={!!task?.completedAt}
              onChange={setAssignedTo}
            />
          </div>
          <div className="sm:w-1/2">
            <GroupSelect
              userId={assignedTo?.id || ''}
              value={defaultGroup}
              disabled={!!task?.completedAt}
              onChange={setGroup}
            />
          </div>
        </div>
      )}
      <div className="sm:pr-2 sm:w-1/2">
        <DateTimePicker
          label="Due date"
          minDate={new Date()}
          showTimeSelect
          dateFormat={dueDateFormat}
          isClearable
          disabled={!!task?.completedAt}
          selected={dueDate}
          onChange={(date: Date) => setDueDate(date)}
        />
      </div>
    </form>
  )
}
