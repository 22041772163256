import { useState, useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import classNames from 'classnames'
import { useLazyQuery } from '@apollo/client'
import { ExclamationCircleIcon, ClipboardDocumentListIcon } from '@heroicons/react/24/outline'
import { useTitle } from '../../hooks'
import { useGlobalStore, useTaskStore } from '../../store'
import { Frame } from '../../components/partials'
import { TaskLoading } from './tasks/TaskLoading'
import { Task } from './tasks/Task'
import { TasksNavBar } from './tasks/TasksNavBar'
import { MobileTaskFilters } from './tasks/MobileTaskFilters'
import { CreateTaskModal } from './tasks/CreateTaskModal'
import { EditTaskModal } from './tasks/EditTaskModal'
import { LIST_TASKS } from '../../graphql'
import { ListTaskInput, ListTaskInputFilterType, ListTaskResponseItem } from '../../types/gql/graphql'
import { Routes } from '../../types'

// Types
export enum AdminFilter {
  AssignedToMe = 'AssignedToMe',
  AssignedByMe = 'AssignedByMe',
  AssignedToOthers = 'AssignedToOthers',
}

export function TasksPage() {
  // Set page title
  useTitle('Tasks')

  // Hooks
  const navigate = useNavigate()

  // Zustand store
  const isOrgAdminOrGroupAdmin = useGlobalStore(state => state.isOrgAdminOrGroupAdmin)
  const authenticatedUserId = useGlobalStore(state => state.authenticatedUserInfo?.getAuthenticatedUser.id)
  const tasksToShow = useTaskStore(state => state.tasksToShow)
  const setTasksToShow = useTaskStore(state => state.setTasksToShow)
  const removeTask = useTaskStore(state => state.removeTask)
  const setCurrentFilters = useTaskStore(state => state.setCurrentFilters)

  // Queries
  const [listTasks, { loading, error, data }] = useLazyQuery(LIST_TASKS)

  // Component state
  const [searchParams] = useSearchParams()
  const [apiInput, setApiInput] = useState<ListTaskInput>({
    filter: ListTaskInputFilterType.All,
    limit: 50,
    offset: 0,
  })
  const [adminFilter, setAdminFilter] = useState<AdminFilter>()
  const [editingTaskId, setEditingTaskId] = useState<string | undefined>()


  // Watchers
  useEffect(() => {
    listTasks({
      variables: {
        input: apiInput
      },
      fetchPolicy: 'network-only'
    })

    // If the user is not an admin and they are attempting to view
    // an admin filter, redirect them to the default filter
    if (!isOrgAdminOrGroupAdmin && adminFilter === AdminFilter.AssignedToOthers) {
      searchParams.delete('adminFilter')
      navigate(`${Routes.Tasks}?${searchParams.toString()}`)
    }

    // If query params includes a task id, open the edit task modal
    if (searchParams.has('id')) {
      if (!searchParams.has('filter')) {
        navigate(`${Routes.Tasks}?filter=ALL&id=${searchParams.get('id')}`)
      }

      setEditingTaskId(searchParams.get('id') || undefined)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Construct the listTasks query whenever the filter query param changes
  useEffect(() => {
    const filter = searchParams.get('filter')
    if (!filter) {
      return
    }

    setCurrentFilters(searchParams)

    const listTasksQuery: ListTaskInput = {
      filter: filter as ListTaskInputFilterType,
      limit: 50,
      offset: 0,
    }

    if (filter === ListTaskInputFilterType.Group) {
      listTasksQuery.groupId = searchParams.get('groupId') || ''
    }

    // Apply admin filter
    if (searchParams.has('adminFilter') && searchParams.get('adminFilter') !== null) {
      const adminFilterQueryParam = searchParams.get('adminFilter') as AdminFilter
      setAdminFilter(adminFilterQueryParam)

      switch (adminFilterQueryParam) {
        case AdminFilter.AssignedToMe:
          delete listTasksQuery.assignedToOthers
          delete listTasksQuery.createdByMe
          break;

        case AdminFilter.AssignedByMe:
          listTasksQuery.assignedToOthers = true
          listTasksQuery.createdByMe = true
          break;

        case AdminFilter.AssignedToOthers:
          listTasksQuery.assignedToOthers = true
          delete listTasksQuery.createdByMe
          break;

        default:
          break;
      }

      if (!searchParams.has('adminFilter') && adminFilter !== undefined) {
        setAdminFilter(undefined)
      }
    } else {
      setAdminFilter(undefined)
    }

    if (isOrgAdminOrGroupAdmin && !searchParams.has('adminFilter')) {
      setAdminFilter(AdminFilter.AssignedToMe)
    }

    setApiInput(listTasksQuery)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams])

  // Fetch tasks when the apiInput changes
  useEffect(() => {
    listTasks({
      variables: {
        input: apiInput
      },
      fetchPolicy: 'network-only'
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiInput])

  // Update the tasks to display
  useEffect(() => {
    if (data?.listTasks) {
      setTasksToShow(data.listTasks as ListTaskResponseItem[])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  return (
    <Frame module="Tasks">
      {/* Page content */}
      <section
        aria-labelledby="primary-heading"
        className="flex h-full min-w-0 flex-1 flex-col overflow-y-auto lg:order-last p-5"
      >
        <h1 id="primary-heading" className="sr-only">Tasks</h1>

        <div className="flex gap-4 justify-center mt-6">
          <div className="w-full md:max-w-[90%] lg:max-w-[75%]">
            <h3 className="text-xs font-medium text-gray-500">
              {isOrgAdminOrGroupAdmin ? 'Filter Tasks' : 'My Tasks'}
            </h3>

            <div className="block sm:hidden mt-2">
              <MobileTaskFilters />
            </div>

            {isOrgAdminOrGroupAdmin && (
              <div className="isolate flex sm:inline-flex mt-2 rounded-md shadow-sm">
                <button
                  type="button"
                  className={classNames(
                    'relative inline-flex justify-center rounded-l-md border border-gray-300 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 w-1/2 sm:w-auto',
                    `${adminFilter === AdminFilter.AssignedToMe ? 'bg-white' : 'bg-gray-100'}`,
                  )}
                  onClick={() => {
                    setAdminFilter(AdminFilter.AssignedToMe)
                    searchParams.set('adminFilter', AdminFilter.AssignedToMe)
                    navigate(`${Routes.Tasks}?${searchParams.toString()}`)
                  }}
                >
                  Assigned to me
                </button>
                <button
                  type="button"
                  className={classNames(
                    'relative inline-flex justify-center border border-l-0 border-gray-300 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 w-1/2 sm:w-auto',
                    `${adminFilter === AdminFilter.AssignedByMe ? 'bg-white' : 'bg-gray-100'}`,
                  )}
                  onClick={() => {
                    setAdminFilter(AdminFilter.AssignedByMe)
                    searchParams.set('adminFilter', AdminFilter.AssignedByMe)
                    navigate(`${Routes.Tasks}?${searchParams.toString()}`)
                  }}
                >
                  Assigned by me
                </button>
                <button
                  type="button"
                  className={classNames(
                    'relative -ml-px inline-flex justify-center rounded-r-md border border-gray-300 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 w-1/2 sm:w-auto',
                    `${adminFilter === AdminFilter.AssignedToOthers ? 'bg-white' : 'bg-gray-100'}`,
                  )}
                  onClick={() => {
                    setAdminFilter(AdminFilter.AssignedToOthers)
                    searchParams.set('adminFilter', AdminFilter.AssignedToOthers)
                    navigate(`${Routes.Tasks}?${searchParams.toString()}`)
                  }}
                >
                  Assigned to others
                </button>
              </div>
            )}
          </div>
        </div>

        <div className="flex flex-col items-center gap-4 mt-6">
          {loading && !error && [1, 2, 3, 4, 5].map((i) => <TaskLoading key={i} />)}

          {!loading && error && (
            <div className="flex flex-col items-center mt-16 text-red-500 text-center w-full sm:w-1/2">
              <ExclamationCircleIcon className="w-12 h-12" />
              <h2 className="mt-2 text-lg font-medium">An error occurred</h2>
              <p className="mt-1 text-sm">
                We couldn't fetch your tasks. Please reload the page or contact support if the issue persists.
              </p>
            </div>
          )}

          {!loading && !error && tasksToShow.length === 0 && (
            <div className="flex flex-col items-center mt-16 text-center w-full sm:w-1/2">
              <ClipboardDocumentListIcon className="text-gray-500 w-12 h-12" />
              <h2 className="mt-2 text-lg font-medium text-gray-900">No tasks found</h2>
              <p className="mt-1 text-sm text-gray-500">
                We couldn't find any tasks that match your filter criteria.
              </p>
            </div>
          )}

          {!loading && !error && tasksToShow && tasksToShow?.length > 0 && tasksToShow.map((t) => (
            <Task
              key={t.id}
              id={t.id}
              isCompleted={searchParams.get('filter') === ListTaskInputFilterType.Completed}
              title={t.title}
              description={t.description}
              due={t.dueDate}
              assignedTo={t.assignedTo}
              group={t.group}
              authenticatedUserId={authenticatedUserId || ''}
              createdBy={t.createdBy}
              onCompleted={removeTask}
              onReopened={removeTask}
              onClick={() => { setEditingTaskId(t.id) }}
            />
          ))}
        </div>

        <CreateTaskModal isMobile />

        <EditTaskModal
          taskId={editingTaskId}
          onCompleted={removeTask}
          onReopened={removeTask}
          onDelete={removeTask}
          onClose={() => {
            setEditingTaskId(undefined)
            if (searchParams.has('id')) {
              searchParams.delete('id')
              navigate(`${Routes.Tasks}?${searchParams.toString()}`)
            }
          }}
        />
      </section>

      {/* Sub-navigation */}
      <TasksNavBar />
    </Frame>
  )
}
