import { graphql } from '../types/gql'

export const EDIT_GROUP = graphql(`
  mutation editGroup($editGroupId: String!, $name: String!) {
    editGroup(id: $editGroupId, name: $name) {
      id
      name
    }
  }
`)
