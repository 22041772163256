import { graphql } from '../types/gql'

export const GET_TASK_COUNT_SUMMARY = graphql(`
  query getTaskCountSummary {
    getTaskCountSummary {
      ALL
      DUETODAY
      NEXTSEVENDAYS
      groups {
        id
        count
      }
    }
  }
`)
