import { graphql } from '../types/gql'

export const LIST_USERS_FOR_ORGANISATION = graphql(`
  query listUsersForOrganisation($skip: Int!, $limit: Int!) {
    listUsersForOrganisation(skip: $skip, limit: $limit) {
      count
      users {
        id
        fullName
        mobileNumber
        groups
        extraGroupCount
        isOrgAdmin
      }
    }
  }
`)

