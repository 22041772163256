import { useState, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { UsersIcon } from '@heroicons/react/24/solid'
import { useLazyQuery } from '@apollo/client'
import { toast } from 'react-toastify'
import { Select } from '../../../components/elements'
import { useGlobalStore } from '../../../store'
import { IdName } from '../../../types/gql/graphql'
import { LIST_GROUPS_FOR_USER } from '../../../graphql'
import { ListTaskInputFilterType } from '../../../types/gql/graphql'

// Local variables
export const noGroup: IdName = { id: 'none', name: 'Select group' }
const loadingGroup: IdName = { id: 'loading', name: 'Fetching groups...' }

// Local types
type GroupSelectProps = {
  onChange: (group: IdName) => void
}

export function NavBarGroupSelect({ onChange }: GroupSelectProps) {
  // Hooks
  const [listGroupsForUser, { loading }] = useLazyQuery(LIST_GROUPS_FOR_USER);

  // Component state
  const authenticatedUserInfo = useGlobalStore((state) => state.authenticatedUserInfo)
  const [searchParams] = useSearchParams()
  const [selectedGroup, setSelectedGroup] = useState<IdName>(noGroup)
  const [groups, setGroups] = useState<IdName[]>([loadingGroup])

  // Get groups for the selected user. Prepend the 'no group' option.
  const getGroups = async () => {
    try {
      const response = await listGroupsForUser({
        variables: {
          input: {
            userId: authenticatedUserInfo?.getAuthenticatedUser.id,
            returnAll: true,
          },
        }
      });

      if (response.error || !response.data?.listGroupsForUser) {
        toast.error('Unable to get a list of your groups. Please reload the page to try again.')
        setGroups([noGroup]);
        return;
      }

      // Transform the response to an array of IdName
      const transformedGroups: IdName[] = response.data.listGroupsForUser.groups.map((group: any) => {
        return {
          id: group.id,
          name: group.name,
        }
      });

      setGroups([...[noGroup], ...transformedGroups]);

      // Set the selected item based on the response
      if (response.data?.listGroupsForUser.groups.length === 0) {
        setSelectedGroup(noGroup);
        onChange(noGroup);
        toast.info('You do not have access to any groups.')
      }
    } catch (error) {
      toast.error('Unable to get a list of your groups. Please reload the page to try again.')
      setGroups([noGroup]);
    }
  }

  // Watchers
  useEffect(() => {
    getGroups()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (loading) {
      setGroups([loadingGroup])
    }
  }, [loading])

  useEffect(() => {
    if (searchParams.has('filter') && searchParams.get('filter') === ListTaskInputFilterType.All && selectedGroup.id !== noGroup.id) {
      setSelectedGroup(noGroup)
      return
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams])

  return (
    <Select
      icon={<UsersIcon />}
      value={selectedGroup}
      items={groups}
      disabled={loading || groups.length === 0 || groups.length === 1}
      onChange={(i) => {
        setSelectedGroup({ id: `${i.id}`, name: i.name })
        onChange({ id: `${i.id}`, name: i.name })
      }}
    />
  )
}
