import { useState } from 'react'
import classNames from 'classnames'
import { PlusIcon } from '@heroicons/react/24/solid'
import { useMutation } from '@apollo/client'
import { toast } from 'react-toastify'
import { Modal, Button } from '../../../components/elements'
import { TaskForm } from './TaskForm'
import { CreateTaskInput } from '../../../types/gql/graphql'
import { CREATE_TASK } from '../../../graphql'
import { useTaskStore } from '../../../store'

type CreateTaskModalProps = {
  isMobile?: boolean
}

export function CreateTaskModal({ isMobile = false }: CreateTaskModalProps) {
  // Hooks
  const [createTask, { loading }] = useMutation(CREATE_TASK)

  // Store
  const addCreatedTask = useTaskStore(state => state.addCreatedTask)

  // Component state
  const [createTaskInput, setCreateTaskInput] = useState<CreateTaskInput>()

  return (
    <Modal
      trigger={(
        <>
          <div className="block sm:hidden fixed bottom-7 right-7">
            <button
              type="button"
              onClick={() => { }}
              className={classNames(
                'flex items-center justify-center',
                'bg-indigo-600 text-white border-transparent shadow-sm hover:bg-indigo-700 disabled:bg-indigo-400 focus:outline-none',
                'rounded-full w-16 h-16'
              )}
            >
              <PlusIcon className="w-9 h-9" />
            </button>
          </div>
          <span className={classNames('hidden', { 'sm:block': !isMobile })}>
            <Button
              text="New task"
              icon={{
                element: <PlusIcon />,
                position: 'left'
              }}
              onClick={() => { }}
              fluid
            />
          </span>
        </>
      )}
      title="New task"
      primaryButton={{
        text: 'Create',
        disabled: !createTaskInput || (!createTaskInput.title || !createTaskInput.assignedTo),
        loading,
        onClick: async (closeModal) => {
          if (!createTaskInput) {
            return
          }

          try {
            const response = await createTask({
              variables: {
                input: createTaskInput
              }
            })

            if (response.errors) {
              toast.error('An error occurred while creating the task. Please try again or contact support.')
              return
            }

            if (response.data?.createTask) {
              closeModal()
              addCreatedTask(response.data.createTask)
              setCreateTaskInput(undefined)
              toast.success('Task created!')
            }
          } catch (error) {
            toast.error('An error occurred while creating the task. Please try again or contact support.')
          }
        }
      }}
    >
      <TaskForm onChange={setCreateTaskInput} />
    </Modal>
  )
}
