import { graphql } from '../types/gql'

export const LIST_GROUPS_FOR_USER = graphql(`
  query listGroupsForUser($input: ListGroupsForUserInput) {
    listGroupsForUser(input: $input) {
      count
      groups {
        id
        name
        isAdmin
      }
    }
  }
`)
