import { PropsWithChildren, Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import classNames from 'classnames'
import { ExclamationTriangleIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { Button } from './Button'

type PrimaryButtonConfig = {
  text: string
  disabled?: boolean
  loading?: boolean
  onClick: (closeModal: () => void) => void
}

type ModalProps = PropsWithChildren<{
  variant?: 'standard' | 'error'
  size?: 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl'
  trigger: JSX.Element
  disableTrigger?: boolean
  title: string
  description?: string
  primaryButton: PrimaryButtonConfig
  hideCancelButton?: boolean
}>

export function Modal({
  variant = 'standard',
  size = 'xl',
  trigger,
  disableTrigger = false,
  title,
  description,
  primaryButton,
  hideCancelButton = false,
  children
}: ModalProps) {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const closeModal = () => {
    setIsOpen(false)
  }

  return (
    <>
      <div
        className="inline-block"
        onClick={(e) => {
          e.stopPropagation()
          if (!disableTrigger) {
            setIsOpen(true)
          }
        }}>
        {trigger}
      </div>

      <Transition.Root show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setIsOpen}>
          {/* Overlay */}
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                {/* Modal */}
                <Dialog.Panel className={`relative transform rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 w-full sm:min-w-xl sm:max-w-xl sm:p-6`}>
                  <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                    <button
                      type="button"
                      className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-white"
                      onClick={() => setIsOpen(false)}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>

                  {/* Content */}
                  <div className="flex items-start gap-4">
                    {variant === 'error' && (
                      <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                        <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                      </div>
                    )}
                    <div className={classNames('w-full', { 'mt-3': variant !== 'error' })}>
                      <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                        {title}
                      </Dialog.Title>
                      <div className="mt-2">
                        {description && <p className="text-sm text-gray-500">{description}</p>}
                        {children && children}
                      </div>
                    </div>
                  </div>

                  {/* Footer */}
                  <div className="mt-5 sm:mt-4 flex flex-row-reverse gap-3">
                    <Button
                      text={primaryButton.text}
                      variant={variant === 'standard' ? 'primary' : 'error'}
                      disabled={primaryButton.disabled}
                      loading={primaryButton.loading}
                      onClick={() => {
                        primaryButton.onClick(closeModal)
                      }}
                    />
                    {!hideCancelButton && (
                      <Button
                        text="Cancel"
                        variant="outline"
                        disabled={primaryButton.loading}
                        onClick={() => setIsOpen(false)}
                      />
                    )}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  )
}
