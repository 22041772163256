import { PropsWithChildren } from 'react'
import { Button, Input, Select } from "../../../components/elements";
import { useGlobalStore } from '../../../store';

// Local data
const timezones = [
  { id: 'Asia/Kolkata', name: 'Asia/Kolkata' },
  { id: 'Europe/Brussels', name: 'Europe/Brussels' },
  { id: 'America/New_York', name: 'America/New_York' }
]

const titles = [
  { id: 'Mr.', name: 'Mr.' },
  { id: 'Ms.', name: 'Ms.' },
  { id: 'Mrs.', name: 'Mrs.' }
]

type SectionProps = PropsWithChildren<{
  title: string
  description: string
}>

function Section({ title, description, children }: SectionProps) {
  return (
    <div className="flex flex-col sm:flex-row gap-8 border-b border-dashed border-gray-300 pb-16 last-of-type:border-none last-of-type:pb-0">
      <div className="sm:pr-12 sm:w-1/3">
        <h4 className="font-semibold">{title}</h4>
        <p className="mt-1 text-sm text-gray-500">
          {description}
        </p>
      </div>
      <div className="sm:w-2/3">
        {children}
      </div>
    </div>
  )
}

export function Settings() {
  // Component state
  const authenticatedUserInfo = useGlobalStore(state => state.authenticatedUserInfo)

  if (!authenticatedUserInfo?.getAuthenticatedUserOrganisation) {
    return null
  }

  return (
    <div className="bg-white rounded-lg shadow">
      <div className="border-b border-gray-200 bg-white px-4 py-5 sm:px-6 rounded-t-lg">
        <div className="-ml-4 -mt-4 flex flex-wrap items-center justify-between sm:flex-nowrap">
          <div className="ml-4 mt-4">
            <h3 className="text-lg font-medium leading-6 text-gray-900">Organisation Settings</h3>
            <p className="mt-1 text-sm text-gray-500">
              Configure your organisation's name, primary contact, etc. This data may be used to to bill you.
            </p>
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-16 px-4 py-5 sm:px-6">
        <Section
          title="General Info"
          description="The display name is used to reference your organisation in notifications, etc. The selected timezone will be used as the default timezone for all users in your organisation until they individually overwrite it."
        >
          <div className="flex flex-col gap-4 sm:pr-36">
            <Input
              label="Display name"
              value={authenticatedUserInfo.getAuthenticatedUserOrganisation.displayName}
              onChange={() => { }}
            />
            <Input label="Legal name" />
            <Select
              label="Default timezone"
              items={timezones}
              onChange={() => { }}
            />
            <div><Button text="Save changes" /></div>
          </div>
        </Section>

        <Section
          title="Address"
          description="We may use this information to generate invoices to bill your organisation."
        >
          <form className="flex flex-col gap-4 sm:pr-36">
            <Input label="Line 1" required />
            <Input label="Line 2" hint="Optional" />
            <div className="flex gap-4">
              <div className="w-1/2"><Input label="City" /></div>
              <div className="w-1/2"><Input label="State" /></div>
            </div>
            <div className="flex gap-4">
              <div className="w-1/2"><Input label="Zip/Pin code" /></div>
              <div className="w-1/2"><Input label="Country" /></div>
            </div>
            <div><Button text="Save changes" /></div>
          </form>
        </Section>

        <Section
          title="Primary contact"
          description="Critical service announcements, billing enquires etc. are sent to this contact."
        >
          <div className="flex flex-col gap-4 sm:pr-36">
            <div className="flex gap-4">
              <div className="w-20">
                <Select
                  label="Title"
                  items={titles}
                  onChange={() => { }}
                />
              </div>
              <div className="flex flex-grow gap-4">
                <div className="w-1/2"><Input label="First name" /></div>
                <div className="w-1/2"><Input label="Last name" /></div>
              </div>
            </div>
            <div className="flex gap-4">
              <div className="w-1/2"><Input label="Email" type="email" /></div>
              <div className="w-1/2"><Input label="Phone number" /></div>
            </div>
            <div><Button text="Save changes" /></div>
          </div>
        </Section>
      </div>
    </div>
  )
}
