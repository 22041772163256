import { graphql } from '../types/gql'

export const EDIT_USER = graphql(`
  mutation editUser($userId: String!, $input: EditUserInput!) {
    editUser(id: $userId, input: $input) {
      id
      firstName
      lastName
      phoneNumber
      email
      jobTitle
    }
  }
`)
