import Skeleton from 'react-loading-skeleton'
import { useGlobalStore } from '../../../store'

export function TaskFormLoading() {
  // Component state
  const authenticatedUserInfo = useGlobalStore(state => state.authenticatedUserInfo)
  const isOrgAdminOrGroupAdmin = useGlobalStore(state => state.isOrgAdminOrGroupAdmin)

  return (
    <div className="flex flex-col gap-4 my-6 mx-6 sm:gap-6 sm:my-6">
      <Skeleton className="w-full h-[38px]" />
      <Skeleton className="w-full h-[98px]" />
      {(isOrgAdminOrGroupAdmin || (authenticatedUserInfo?.listGroupsForUser && authenticatedUserInfo?.listGroupsForUser.count > 1)) && (
        <div className="flex flex-col gap-4 sm:flex-row">
          <div className="sm:w-1/2">
            <Skeleton className="w-full h-[38px]" />
          </div>
          <div className="sm:w-1/2">
            <Skeleton className="w-full h-[38px]" />
          </div>
        </div>
      )}
      <div className="sm:pr-2 sm:w-1/2">
        <Skeleton className="w-full h-[38px]" />
      </div>
      <Skeleton className="w-32 h-[38px]" />
    </div>
  )
}