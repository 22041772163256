import { useState, useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { Combobox, ComboboxItem } from '../../../components/elements'
import { useGlobalStore } from '../../../store'
import { ListTaskInputFilterType } from '../../../types/gql/graphql'
import { LIST_GROUPS_FOR_USER } from '../../../graphql'
import { Routes } from '../../../types'

const baseFilters: ComboboxItem[] = [
  { id: ListTaskInputFilterType.All, name: 'All tasks' },
  { id: ListTaskInputFilterType.Duetoday, name: 'Due today' },
  { id: ListTaskInputFilterType.Nextsevendays, name: 'Next 7 days' },
  { id: ListTaskInputFilterType.Completed, name: 'Completed' }
]

export function MobileTaskFilters() {
  // Component state
  const authenticatedUserInfo = useGlobalStore(state => state.authenticatedUserInfo)
  const [items, setItems] = useState<ComboboxItem[]>(baseFilters)
  const [selectedFilter, setSelectedFilter] = useState<ComboboxItem>(baseFilters[0])

  // Hooks
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const { loading, error, data } = useQuery(LIST_GROUPS_FOR_USER, {
    variables: {
      input: {
        userId: authenticatedUserInfo?.getAuthenticatedUser.id,
        returnAll: true,
      }
    }
  })

  // Functions
  const handleFilterChange = () => {
    if (searchParams.has('filter') && searchParams.get('filter') !== ListTaskInputFilterType.Group) {
      const filter = baseFilters.find(f => f.id === searchParams.get('filter'))
      if (filter) {
        setSelectedFilter(filter)
      }
    }

    if (searchParams.has('filter') && searchParams.get('filter') === ListTaskInputFilterType.Group && searchParams.has('groupId')) {
      const filter = items.find(f => f.id === searchParams.get('groupId'))
      if (filter) {
        setSelectedFilter(filter)
      }
    }
  }

  // Watchers
  useEffect(() => {
    handleFilterChange()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!loading && !error && data?.listGroupsForUser?.groups) {
      const groups = data.listGroupsForUser.groups.map((group: any) => {
        return {
          id: group.id,
          name: group.name
        }
      })

      setItems([...items, ...groups])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  useEffect(() => {
    if (items) {
      handleFilterChange()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items])

  return (
    <Combobox
      items={items}
      value={selectedFilter}
      onChange={(i) => {
        if (i.id.toString().length > 14) {
          navigate(`${Routes.Tasks}?filter=${ListTaskInputFilterType.Group}&groupId=${i.id}`)
        } else {
          navigate(`${Routes.Tasks}?filter=${i.id}`)
        }
      }}
    />
  )
}
