import { graphql } from '../types/gql'

export const GET_SINGLE_TASK = graphql(`
  query getSingleTask($id: String!) {
    getSingleTask(id: $id) {
      id
      organisation
      isDeleted
      completedAt
      title
      description
      assignedTo {
        id
        name
      }
      group {
        id
        name
      }
      dueDate
      subtasks {
        id
        task
        completed
      }
      activityLog {
        id
        type
        actor
        note
        when
      }
      createdBy {
        id
        name
      }
      createdAt
      updatedAt
    }
  }
`)
