import { graphql } from '../types/gql'

export const TOGGLE_SUB_TASK_COMPLETION = graphql(`
  mutation toggleSubTaskCompletion($input: ToggleSubTaskCompletionInput!) {
    toggleSubTaskCompletion(input: $input) {
      updatedSubTasks {
        id
        task
        completed
      }
    }
  }
`)
