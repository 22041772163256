import { useState, useEffect, useMemo } from 'react'
import classNames from 'classnames'
import { useLazyQuery } from '@apollo/client'
import { PulseLoader, SlideOut, Tabs, Pagination } from "../../../components/elements"
import { CreateGroupModal } from './modals'
import { ListGroupsForOrganisationItem } from '../../../types/gql/graphql'
import { LIST_GROUPS_FOR_ORGANISATION } from '../../../graphql'
import { useGlobalStore } from '../../../store'
import { EditGroupDetails, EditGroupMembers } from './partials'

// Local types
type Group = ListGroupsForOrganisationItem & {
  isNew?: boolean
}

// Local consts
const MAX_GROUPS_PER_PAGE = 10

export function Groups() {
  // Component state
  const authenticatedUserFullName = useGlobalStore(state => state.authenticatedUserFullName)
  const [skip, setSkip] = useState<number>(0)
  const [totalCount, setTotalCount] = useState<number>(0)
  const [groups, setGroups] = useState<Group[]>([])

  // Queries
  const [listGroupsForOrganisation, { loading, error, data }] = useLazyQuery(LIST_GROUPS_FOR_ORGANISATION)

  // Watchers
  useEffect(() => {
    listGroupsForOrganisation({
      variables: {
        skip,
        limit: MAX_GROUPS_PER_PAGE
      },
      fetchPolicy: 'network-only'
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    listGroupsForOrganisation({
      variables: {
        skip,
        limit: MAX_GROUPS_PER_PAGE
      },
      fetchPolicy: 'network-only'
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skip])

  useEffect(() => {
    if (!error && !loading && data?.listGroupsForOrganisation && data.listGroupsForOrganisation.count > 0) {
      const groupsToShow: Group[] = []
      data.listGroupsForOrganisation.groups.forEach((group) => {
        groupsToShow.push({
          id: group?.id || '',
          name: group?.name || '',
          userCount: group?.userCount || 0,
          admins: group?.admins || [],
          isNew: false
        })
      })

      setGroups(groupsToShow)

      // Set total count
      setTotalCount(data.listGroupsForOrganisation.count)
    }
  }, [error, loading, data])

  // Memoised Data
  const totalPages = useMemo(() => {
    if (data?.listGroupsForOrganisation) {
      return Math.ceil(totalCount / MAX_GROUPS_PER_PAGE)
    }

    return 0
  }, [data, totalCount])

  // Adds new groups to the table
  const addNewGroupToTable = (id: string, name: string) => {
    setGroups((prevGroups) => {
      return [
        {
          id,
          name,
          userCount: 1,
          admins: [authenticatedUserFullName || ''],
          isNew: true
        },
        ...prevGroups
      ]
    })

    setTotalCount(currentCount => currentCount + 1)
  }

  // Updates the name of a group in the table
  const updateGroupName = (id: string, name: string) => {
    setGroups((prevGroups) => {
      return prevGroups.map((group) => {
        if (group.id === id) {
          return {
            ...group,
            name
          }
        }

        return group
      })
    })
  }

  // Deletes a group from the table
  const deleteGroupFromTable = (id: string) => {
    setGroups((prevGroups) => {
      return prevGroups.filter((group) => group.id !== id)
    })
  }

  return (
    <div className="bg-white rounded-lg shadow">
      <div className="border-b border-gray-200 bg-white px-4 py-5 sm:px-6 rounded-t-lg">
        <div className="-ml-4 -mt-4 flex flex-wrap items-center justify-between sm:flex-nowrap">
          <div className="ml-4 mt-4">
            <h3 className="text-lg font-medium leading-6 text-gray-900">Groups</h3>
            <p className="mt-1 text-sm text-gray-500">
              Organise your users into groups and assign specific users as group admins.
            </p>
          </div>
          <div className="flex gap-3 ml-4 mt-4">
            {/* <Input
              placeholder="Search groups"
              icon={{ element: <MagnifyingGlassIcon />, position: "left" }}
              style={{ width: '250px' }}
            /> */}
            <CreateGroupModal onCreated={addNewGroupToTable} />
          </div>
        </div>
      </div>

      <div className="overflow-hidden ring-1 ring-black ring-opacity-5 rounded-b-lg">
        <table className="min-w-full divide-y divide-gray-300">
          <thead className="bg-gray-50">
            <tr>
              <th
                scope="col"
                className="py-3 pl-4 pr-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500 sm:pl-6"
              >
                Name
              </th>
              <th
                scope="col"
                className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
              >
                Admins
              </th>
              <th
                scope="col"
                className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
              >
                No. of Users
              </th>
              <th scope="col" className="relative py-3 pl-3 pr-4 sm:pr-6">
                <span className="sr-only">Edit</span>
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            {loading && Array.from(Array(10).keys()).map((i) => (
              <tr key={i}>
                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                  <PulseLoader />
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                  <PulseLoader width={80} />
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                  <PulseLoader width={7} />
                </td>
                <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                  <PulseLoader width={9} />
                </td>
              </tr>
            ))}

            {!loading && error && (
              <tr>
                <td colSpan={4} className="whitespace-nowrap px-3 py-4 text-sm text-red-700">
                  An error occurred while we attempted to load your groups. Please reload the page and try again or contact support.
                </td>
              </tr>
            )}

            {!loading && !error && groups.length === 0 && (
              <tr>
                <td colSpan={4} className="whitespace-nowrap px-3 py-4 text-sm">
                  You haven't created any groups yet.
                </td>
              </tr>
            )}

            {!loading && !error && groups.length > 0 && groups.map((g) => (
              <tr key={g.id} className={classNames({ 'animate-fade-green-bg': g.isNew })}>
                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                  {g.name}
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                  {g.admins.length > 0 ? g.admins.slice(0, 2).join(', ') : 'None'}
                  {g.admins && g.admins.length > 2 && ` +${g.admins.length - 2}`}
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                  {g.userCount}
                </td>
                <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                  <SlideOut
                    trigger={(
                      <button className="text-indigo-600 hover:text-indigo-900">
                        Edit
                      </button>
                    )}
                    title="Edit group"
                  >
                    <Tabs
                      tabs={{
                        'Details': { element: <EditGroupDetails id={g.id} name={g.name} onUpdate={updateGroupName} onDelete={deleteGroupFromTable} /> },
                        'Members': {
                          element: (
                            <EditGroupMembers
                              id={g.id}
                              onMembersChanged={(admins, userCount) => {
                                // Update group in table with new admins and user count
                                setGroups(groups.map((group) => {
                                  if (group.id === g.id) {
                                    return {
                                      ...group,
                                      admins,
                                      userCount,
                                    }
                                  }
                                  return group
                                }))
                              }}
                            />
                          )
                        }
                      }}
                    />
                  </SlideOut>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {!error && data?.listGroupsForOrganisation && totalPages > 1 && (
          <div className="flex justify-end border-t border-gray-200 py-6 px-4">
            <Pagination
              pageCount={totalPages}
              forcePage={skip / MAX_GROUPS_PER_PAGE}
              onPageChange={(s) => { setSkip(s.selected * MAX_GROUPS_PER_PAGE) }}
            />
          </div>
        )}
      </div>
    </div>
  )
}