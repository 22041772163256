import { useState, useEffect } from 'react'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

type PhoneNumberInputProps = {
  label?: string
  hint?: string
  value?: string
  disabled?: boolean
  onChange: (value: string) => void
}

export function PhoneNumberInput({ label, hint, value, disabled, onChange }: PhoneNumberInputProps) {
  // Component state
  const [inputValue, setInputValue] = useState<string>()

  // Set input value if present
  useEffect(() => {
    setInputValue(value)
  }, [value])

  return (
    <div>
      {(label || hint) && (
        <div className="flex justify-between mb-1">
          <label htmlFor="email" className="block text-sm font-medium text-gray-700">
            {label}
          </label>
          <span className="text-sm text-gray-500" id="email-optional">
            {hint}
          </span>
        </div>
      )}

      <PhoneInput
        placeholder="Enter phone number"
        defaultCountry="IN"
        international
        withCountryCallingCode
        value={inputValue}
        disabled={disabled}
        onChange={(v) => { setInputValue(v); onChange(v?.toString() || '') }}
      />
    </div>
  )
}