import { useState, useEffect, useMemo } from 'react'
import classNames from 'classnames'
import { useLazyQuery } from '@apollo/client'
import { PulseLoader, SlideOut, Tabs, Pagination } from '../../../components/elements'
import { CreateUserModal } from './modals'
import { ListUsersForOrganisationItem } from '../../../types/gql/graphql'
import { LIST_USERS_FOR_ORGANISATION } from '../../../graphql'
import { EditUserDetails } from './partials/EditUserDetails'
import { EditUserGroups } from './partials/EditUserGroups'

// Local types
export type User = ListUsersForOrganisationItem & {
  isNew?: boolean
}

// Local consts
const MAX_USERS_PER_PAGE = 10

export function Users() {
  // Component state
  const [skip, setSkip] = useState<number>(0)
  const [totalCount, setTotalCount] = useState<number>(0)
  const [users, setUsers] = useState<User[]>([])

  // Queries
  const [listUsersForOrganisation, { loading, error, data }] = useLazyQuery(
    LIST_USERS_FOR_ORGANISATION,
    { fetchPolicy: 'network-only' }
  )

  // Watchers
  useEffect(() => {
    listUsersForOrganisation({
      variables: {
        skip,
        limit: MAX_USERS_PER_PAGE
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    listUsersForOrganisation({
      variables: {
        skip,
        limit: MAX_USERS_PER_PAGE
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skip])

  useEffect(() => {
    if (!error && !loading && data?.listUsersForOrganisation && data.listUsersForOrganisation.count > 0) {
      const usersToShow: User[] = []
      data.listUsersForOrganisation.users.forEach((u) => {
        usersToShow.push({
          id: u?.id || '',
          fullName: u?.fullName || '',
          mobileNumber: u?.mobileNumber || '',
          groups: u?.groups || [],
          extraGroupCount: u?.extraGroupCount || 0,
          isOrgAdmin: u?.isOrgAdmin || false,
          isNew: false
        })
      })

      setUsers(usersToShow)

      // Set the total count
      setTotalCount(data.listUsersForOrganisation.count)
    }
  }, [error, loading, data])

  // Memoized data
  const totalPages = useMemo(() => {
    if (data?.listUsersForOrganisation) {
      return Math.ceil(totalCount / MAX_USERS_PER_PAGE)
    }

    return 0
  }, [data, totalCount])

  // Adds new users to the table
  const addNewUserToTable = (user: User) => {
    setUsers((prevUsers) => {
      return [
        user,
        ...prevUsers
      ]
    })

    setTotalCount(currentCount => currentCount + 1)
  }

  // Updates an existing user in the table
  const handleUpdateUser = (id: string, fullName: string, isOrgAdmin: boolean) => {
    setUsers((prevUsers) => {
      return prevUsers.map((user) => {
        if (user.id === id) {
          return {
            ...user,
            fullName,
            isOrgAdmin
          }
        }

        return user
      })
    })
  }

  return (
    <div className="bg-white rounded-lg shadow">
      <div className="border-b border-gray-200 bg-white px-4 py-5 sm:px-6 rounded-t-lg">
        <div className="-ml-4 -mt-4 flex flex-wrap items-center justify-between sm:flex-nowrap">
          <div className="ml-4 mt-4">
            <h3 className="text-lg font-medium leading-6 text-gray-900">Users</h3>
            <p className="mt-1 text-sm text-gray-500">
              Invite users to your organisation and add them to groups as users or admins.
            </p>
          </div>
          <div className="flex gap-3 ml-4 mt-4">
            {/* <Input
              placeholder="Search users"
              icon={{ element: <MagnifyingGlassIcon />, position: "left" }}
              style={{ width: '250px' }}
            /> */}
            <CreateUserModal onCreated={addNewUserToTable} />
          </div>
        </div>
      </div>

      <div className="overflow-hidden ring-1 ring-black ring-opacity-5 rounded-b-lg">
        <table className="min-w-full divide-y divide-gray-300">
          <thead className="bg-gray-50">
            <tr>
              <th
                scope="col"
                className="py-3 pl-4 pr-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500 sm:pl-6"
              >
                Name
              </th>
              <th
                scope="col"
                className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
              >
                Mobile number
              </th>
              <th
                scope="col"
                className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
              >
                Groups
              </th>
              <th scope="col" className="relative py-3 pl-3 pr-4 sm:pr-6">
                <span className="sr-only">Edit</span>
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            {loading && Array.from(Array(10).keys()).map((i) => (
              <tr key={i}>
                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                  <PulseLoader width={52} />
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                  <PulseLoader width={52} />
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                  <PulseLoader width={80} />
                </td>
                <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                  <PulseLoader width={9} />
                </td>
              </tr>
            ))}

            {!loading && error && (
              <tr>
                <td colSpan={4} className="whitespace-nowrap px-3 py-4 text-sm text-red-700">
                  An error occurred while we attempted to load your groups. Please reload the page and try again or contact support.
                </td>
              </tr>
            )}

            {!loading && !error && users.length > 0 && users.map((u) => (
              <tr key={u.id} className={classNames({ 'animate-fade-green-bg': u.isNew })}>
                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                  {u.fullName}
                  {u.isOrgAdmin && (
                    <span className="inline-flex ml-2 items-center rounded-full bg-gray-100 px-2.5 py-0.5 text-xs font-medium text-gray-800">
                      Org Admin
                    </span>
                  )}
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{u.mobileNumber}</td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                  {u.groups.length === 0 && 'No groups'}
                  {u.groups.join(', ')}
                  {u.extraGroupCount > 0 && ` + ${u.extraGroupCount} more`}
                </td>
                <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                  <SlideOut
                    trigger={(
                      <button className="text-indigo-600 hover:text-indigo-900">
                        Edit
                      </button>
                    )}
                    title="Edit User"
                  >
                    <Tabs
                      tabs={{
                        'Details': {
                          element: (
                            <EditUserDetails
                              id={u.id}
                              mobileNumber={u.mobileNumber}
                              isOrgAdmin={u.isOrgAdmin}
                              onUpdated={(fullName, isOrgAdmin) => { handleUpdateUser(u.id, fullName, isOrgAdmin) }}
                              onDelete={() => {
                                setUsers(users.filter((u2) => u2.id !== u.id))
                              }}
                            />
                          )
                        },
                        'Groups': { element: <EditUserGroups id={u.id} onGroupsChanged={() => { }} /> }
                      }}
                    />
                  </SlideOut>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {!error && data?.listUsersForOrganisation && totalPages > 1 && (
          <div className="flex justify-end border-t border-gray-200 py-6 px-4">
            <Pagination
              pageCount={totalPages}
              forcePage={skip / MAX_USERS_PER_PAGE}
              onPageChange={(s) => { setSkip(s.selected * MAX_USERS_PER_PAGE) }}
            />
          </div>
        )}
      </div>
    </div>
  )
}