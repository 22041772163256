import { graphql } from '../types/gql'

export const LIST_GROUPS_FOR_ORGANISATION = graphql(`
  query listGroupsForOrganisation($skip: Int!, $limit: Int!) {
    listGroupsForOrganisation(skip: $skip, limit: $limit) {
      count
      groups {
        id
        name
        admins
        userCount
      }
    }
  }
`)

