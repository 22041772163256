import { useState, useEffect, useMemo } from 'react'
import { format, formatRelative } from 'date-fns'
import {
  PencilIcon, CheckIcon, TrashIcon, CheckCircleIcon as CheckCircleOutline,
  ArrowUturnLeftIcon
} from '@heroicons/react/24/outline'
import { CheckCircleIcon as CheckCircleSolid } from '@heroicons/react/24/solid'
import { useMutation } from '@apollo/client'
import { toast } from 'react-toastify'
import { debounce } from 'lodash'
import { CreateTaskInput, GetSingleTaskResponse, TaskSubTaskInput } from '../../../types/gql/graphql'
import { Button, Modal, Textarea } from '../../../components/elements'
import { TaskForm } from './TaskForm'
import { DELETE_TASK, EDIT_TASK, MARK_TASK_AS_COMPLETED, REOPEN_TASK, TOGGLE_SUB_TASK_COMPLETION } from '../../../graphql'
import { useTaskStore } from '../../../store'

// Local helper functions
const getFormattedDueDate = (date: string): string => {
  const current = new Date()
  const dueDate = new Date(parseInt(date))

  // Construct the relative date string
  let relativeDate = formatRelative(dueDate, current).replace('at 11:59 PM', '')

  // If the relativeDate contains "/", format it with the full date and time
  if (relativeDate.includes('/')) {
    relativeDate = format(dueDate, 'MMM do, yyyy - hh:mm aa').replace('-', 'at').replace('at 11:59 PM', '')
  }

  // If the first letter of the relativeDate is lowercase, capitalise it
  if (relativeDate[0] === relativeDate[0].toLowerCase()) {
    relativeDate = relativeDate[0].toUpperCase() + relativeDate.slice(1)
  }

  return relativeDate
}

// Local components
type SubTaskProps = {
  id: string
  task: string
  completed?: boolean
  onClick: (task: TaskSubTaskInput) => void
}

function SubTask({ id, task, completed = false, onClick }: SubTaskProps) {
  // Component state
  const [isCompleted, setIsCompleted] = useState<boolean>(completed)

  // Actions
  const onSubTaskClick = () => {
    setIsCompleted(!isCompleted)
    onClick({ id, task, completed: !isCompleted })
  }

  return (
    <div className="flex gap-2 items-start hover:cursor-pointer" onClick={() => { onSubTaskClick() }}>
      {isCompleted ? (
        <CheckCircleSolid className="flex-shrink-0 text-indigo-600 w-6 h-6" />
      ) : (
        <CheckCircleOutline className="flex-shrink-0 text-gray-400 hover:text-indigo-600 w-6 h-6" />
      )}
      <span className="block border-b border-gray-200 pb-2 w-full">{task}</span>
    </div>
  )
}

// Local types
type EditTaskTabProps = {
  task: GetSingleTaskResponse
  onCompleted: (id: string) => void
  onReopened: (id: string) => void
  onDelete: (taskId: string) => void
}

export function EditTaskTab({ task, onCompleted, onReopened, onDelete }: EditTaskTabProps) {
  // Hooks
  const [markTaskAsCompleted, { loading: markAsCompletedLoading }] = useMutation(MARK_TASK_AS_COMPLETED)
  const [reopenTask, { loading: reopenLoading }] = useMutation(REOPEN_TASK)
  const [editTask, { loading: editTaskLoading }] = useMutation(EDIT_TASK)
  const [deleteTask, { loading: deleteLoading }] = useMutation(DELETE_TASK)
  const [toggleSubTaskCompletion] = useMutation(TOGGLE_SUB_TASK_COMPLETION)

  // Store
  const authenticatedUserId = useTaskStore(state => state.authenticatedUserId)
  const updateTaskInStore = useTaskStore(state => state.updateTask)

  // Component state
  const [currentTask, setCurrentTask] = useState<GetSingleTaskResponse>(task)
  const [isEditing, setIsEditing] = useState<boolean>(false)
  const [completedSubTasksCount, setCompletedSubTasksCount] = useState<number>(task.subtasks.filter(subtask => subtask?.completed).length)
  const [taskCompleted, setTaskCompleted] = useState<boolean>(task.completedAt ? true : false)
  const [completionComment, setCompletionComment] = useState<string>('')
  const [taskData, setTaskData] = useState<CreateTaskInput>()
  const [subTaskChanges, setSubTaskChanges] = useState<TaskSubTaskInput[]>([]);

  // Computed state
  const disableCompleteButton = useMemo(() => {
    // Do not allow the user to click the button if the task is already completed
    if (task.completedAt) return true

    // Do not allow the user to click the button if there are sub-tasks and not all of them are completed
    if (task.subtasks && task.subtasks.length > 0 && completedSubTasksCount !== task.subtasks.length) return true

    return false
  }, [completedSubTasksCount, task.completedAt, task.subtasks])

  const isTaskCreater = useMemo(() => {
    if (task.createdBy === undefined || task.createdBy === null) {
      return true
    }

    return task.createdBy.id === authenticatedUserId
  }, [authenticatedUserId, task.createdBy])

  // Watchers
  useEffect(() => {
    handleToggleSubTaskCompletion(subTaskChanges);
    return () => handleToggleSubTaskCompletion.cancel(); // Cancel debounced call on unmount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subTaskChanges]);

  // Actions
  const markAsCompleted = async (): Promise<boolean> => {
    setTaskCompleted(true)

    try {
      const response = await markTaskAsCompleted({
        variables: {
          id: task.id,
          ...(completionComment && { comment: completionComment })
        }
      })

      if (response.errors) {
        toast.error('An error occurred while marking the task as completed. Please try again or contact support.')
        setTaskCompleted(false)
        return Promise.resolve(false)
      }

      if (response.data?.markTaskAsCompleted) {
        return Promise.resolve(true)
      }

      return Promise.resolve(false)
    } catch (error) {
      toast.error('An error occurred while marking the task as completed. Please try again or contact support.')
      setTaskCompleted(false)
      return Promise.resolve(false)
    }
  }

  const reopenCompletedTask = async (): Promise<boolean> => {
    setTaskCompleted(true)

    try {
      const response = await reopenTask({
        variables: {
          id: task.id
        }
      })

      if (response.errors || !response.data?.reopenTask) {
        toast.error('An error occurred while attempting to re-open the task. Please try again or contact support.')
        return Promise.resolve(false)
      }

      return Promise.resolve(true)
    } catch (error) {
      toast.error('An error occurred while attempting to re-open the task. Please try again or contact support.')
      return Promise.resolve(false)
    }
  }

  const hanldeUpdate = async (): Promise<boolean> => {
    if (!task || !task.id) {
      return Promise.resolve(false)
    }

    try {
      if (!taskData) {
        return Promise.resolve(false)
      }

      const response = await editTask({
        variables: {
          editTaskId: task.id,
          input: taskData
        }
      })

      if (response.errors || !response.data?.editTask) {
        return Promise.resolve(false)
      }

      updateTaskInStore(response.data.editTask)
      setCurrentTask({
        ...task,
        title: response.data.editTask.title,
        description: response.data.editTask.description,
        dueDate: response.data.editTask.dueDate,
        assignedTo: response.data.editTask.assignedTo,
        group: response.data.editTask.group
      })

      return Promise.resolve(true)
    } catch (error) {
      return Promise.resolve(false)
    }
  }

  const handleDelete = async (): Promise<boolean> => {
    try {
      const response = await deleteTask({
        variables: {
          id: task.id
        }
      })

      if (response.errors || !response.data?.deleteTask) {
        return Promise.resolve(false)
      }

      return Promise.resolve(true)
    } catch (error) {
      return Promise.resolve(false)
    }
  }

  const handleToggleSubTaskCompletion = debounce(async (changes: TaskSubTaskInput[]) => {
    if (subTaskChanges.length === 0) {
      return
    }

    try {
      const response = await toggleSubTaskCompletion({
        variables: {
          input: {
            taskId: task.id,
            subTasks: subTaskChanges
          }
        }
      })

      if (response.errors || !response.data?.toggleSubTaskCompletion) {
        toast.error('An error occurred while updating the sub-tasks. Please try again or contact support.')
        return
      }

      setSubTaskChanges([])
    } catch (error) {
      toast.error('An error occurred while updating the sub-tasks. Please try again or contact support.')
    }
  }, 1000);

  return (
    <>
      {!isEditing && (
        <>
          <div className="flex flex-col gap-8">
            <div className="flex flex-col gap-1">
              <span className="block text-sm font-bold text-gray-900">
                Title
              </span>
              <span className="block">{currentTask.title}</span>
            </div>
            {task.description && (
              <div className="flex flex-col gap-1">
                <span className="block text-sm font-bold text-gray-900">
                  Description
                </span>
                <span className="block">{currentTask.description}</span>
              </div>
            )}
            {currentTask.subtasks && currentTask.subtasks.length > 0 && (
              <div className="flex flex-col gap-1">
                <span className="block text-sm font-bold text-gray-900">
                  Sub-tasks ({completedSubTasksCount}/{currentTask.subtasks.length} completed)
                </span>
                <span className="text-sm">
                  You must complete all sub-tasks to mark this task as <i>completed</i>.
                </span>
                <div className="flex flex-col gap-4 mt-2 pt-2">
                  {currentTask.subtasks.map((s) => {
                    if (!s) {
                      return null
                    }

                    return (
                      <SubTask
                        key={s?.id}
                        id={s.id}
                        task={s.task}
                        completed={s.completed}
                        onClick={(t) => {
                          setCompletedSubTasksCount(t.completed ? completedSubTasksCount + 1 : completedSubTasksCount - 1)

                          if (!subTaskChanges.some(existingChange => existingChange.id === t.id)) {
                            setSubTaskChanges(prevChanges => [...prevChanges, t]);
                          }
                        }}
                      />
                    )
                  })}
                </div>
              </div>
            )}
            <div className="flex flex-col gap-1">
              <span className="block text-sm font-bold text-gray-900">
                Assigned to
              </span>
              <span className="block">{currentTask.assignedTo?.name} ({currentTask.group ? currentTask.group.name : 'No Group'})</span>
            </div>
            <div className="flex flex-col gap-1">
              <span className="block text-sm font-bold text-gray-900">
                Due date
              </span>
              <span className="block">
                {currentTask.dueDate ? getFormattedDueDate(currentTask.dueDate) : 'No due date'}
              </span>
            </div>
          </div>

          <div className="flex gap-2 mt-6">
            {!taskCompleted && !currentTask.completedAt && (
              <Modal
                trigger={
                  <Button
                    size="md"
                    icon={{
                      position: 'left',
                      element: <CheckIcon />,
                    }}
                    text="Mark as completed"
                    disabled={disableCompleteButton}
                  />
                }
                disableTrigger={disableCompleteButton}
                title="Add a comment"
                description="You can add an optional comment to this task before marking it as completed. Your comment will be visible to all subscribers of this task."
                primaryButton={{
                  text: 'Submit',
                  loading: markAsCompletedLoading,
                  onClick: async function (close) {
                    const completed = await markAsCompleted()
                    if (completed) {
                      toast.success('Task completed!')
                      close()
                      onCompleted(currentTask.id)
                    }
                  }
                }}
              >
                <div className="mt-4">
                  <Textarea
                    label="Comment"
                    hint="Optional"
                    value={completionComment}
                    onChange={(e) => { setCompletionComment(e.target.value) }}
                  />
                </div>
              </Modal>
            )}

            {taskCompleted && currentTask.completedAt && (
              <Modal
                trigger={(
                  <Button
                    size="md"
                    icon={{
                      position: 'left',
                      element: <ArrowUturnLeftIcon />,
                    }}
                    text="Re-open task"
                  />
                )}
                title="Re-open task?"
                description="Are you sure you want to re-open this task? This action will remove it from the completed tasks list."
                primaryButton={{
                  text: 'Re-open',
                  loading: reopenLoading,
                  onClick: async function (close) {
                    const reopened = await reopenCompletedTask()
                    if (reopened) {
                      close()
                      onReopened(currentTask.id)
                      toast.success('Task re-opened!')
                    }
                  }
                }}
              />
            )}

            <Button
              variant="outline"
              size="md"
              icon={{
                element: <PencilIcon />,
                position: 'center'
              }}
              disabled={!!currentTask.completedAt || !isTaskCreater}
              onClick={() => { setIsEditing(true) }}
            />

            <Modal
              variant="error"
              trigger={(
                <Button
                  variant="outline"
                  size="md"
                  icon={{
                    element: <TrashIcon />,
                    position: 'center'
                  }}
                  disabled={!!currentTask.completedAt || !isTaskCreater}
                />
              )}
              disableTrigger={!!currentTask.completedAt || !isTaskCreater}
              title="Delete task?"
              description="Are you sure you want to delete this task? This action cannot be undone."
              primaryButton={{
                text: 'Delete',
                loading: deleteLoading,
                onClick: async function (close) {
                  const isDeleted = await handleDelete()

                  if (isDeleted) {
                    close()
                    onDelete(currentTask.id)
                    toast.success('Task deleted!')
                  } else {
                    close()
                    toast.error('Unable to delete this task. Please try again or contact support if the issue persists.')
                  }
                }
              }}
            />
          </div>
        </>
      )}

      {isEditing && (
        <>
          <TaskForm
            task={currentTask}
            onChange={setTaskData}
          />

          <div className="flex gap-2 mt-6">
            <Button
              size="md"
              text="Save changes"
              loading={editTaskLoading}
              onClick={async () => {
                const updated = await hanldeUpdate()
                if (updated) {
                  setIsEditing(false)
                } else {
                  toast.error('Error updating task. Please try again or contact support.')
                }
              }}
            />
            <Button
              variant="outline"
              size="md"
              text="Cancel"
              onClick={() => { setIsEditing(false) }}
            />
          </div>
        </>
      )}
    </>
  )
}