import { useState } from "react"
import classNames from "classnames"

type TabItem = {
  element: JSX.Element
}

type TabsProps = {
  tabs: { [key: string]: TabItem }
  selectedTabName?: string
}

export function Tabs({ tabs, selectedTabName }: TabsProps) {
  const [selected, setSelected] = useState<string>(selectedTabName || Object.keys(tabs)[0])

  return (
    <div>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        <select
          id="tabs"
          name="tabs"
          className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
          defaultValue={selected}
          onChange={(e) => setSelected(e.target.value)}
        >
          {Object.keys(tabs).map((tabName) => (
            <option key={tabName} value={tabName}>
              {tabName}
            </option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex" aria-label="Tabs">
            {Object.keys(tabs).map((tabName) => (
              <button
                key={tabName}
                className={classNames(
                  tabName === selected
                    ? 'border-indigo-500 text-indigo-600'
                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                  'w-1/4 py-4 px-1 text-center border-b-2 font-medium text-sm'
                )}
                onClick={() => setSelected(tabName)}
                aria-current={tabName === selected ? 'page' : undefined}
              >
                {tabName}
              </button>
            ))}
          </nav>
        </div>
      </div>

      <div className="mt-6">
        {tabs[selected].element}
      </div>
    </div>
  )
}
