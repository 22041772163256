import { graphql } from '../types/gql'

export const CREATE_GROUP = graphql(`
  mutation createGroup($name: String!) {
    createGroup(name: $name) {
      id
      name
    }
  }
`)
