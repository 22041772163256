import { useState, useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import classNames from 'classnames'
import { CheckCircleIcon, CalendarIcon, CalendarDaysIcon, CheckIcon } from '@heroicons/react/24/outline'
import { useQuery } from '@apollo/client'
import { CreateTaskModal } from "./CreateTaskModal"
import { useGlobalStore, useTaskStore } from '../../../store'
import { NavBarGroupSelect, noGroup } from './NavBarGroupSelect'
import { Routes } from '../../../types'
import { GET_TASK_COUNT_SUMMARY } from '../../../graphql'
import { ListTaskInputFilterType } from '../../../types/gql/graphql'

// Local data
const tasksMainNavItems = [
  { name: 'All tasks', queryFilter: ListTaskInputFilterType.All, icon: CheckCircleIcon },
  { name: 'Due today', queryFilter: ListTaskInputFilterType.Duetoday, icon: CalendarIcon },
  { name: 'Next 7 days', queryFilter: ListTaskInputFilterType.Nextsevendays, icon: CalendarDaysIcon },
  { name: 'Completed', queryFilter: ListTaskInputFilterType.Completed, icon: CheckIcon }
]

export function TasksNavBar() {
  // Hooks
  const navigate = useNavigate()
  const { loading, error, data } = useQuery(GET_TASK_COUNT_SUMMARY)

  // Component state
  const authenticatedUserInfo = useGlobalStore(state => state.authenticatedUserInfo)
  const taskCounts = JSON.parse(JSON.stringify(useTaskStore(state => state.taskCountSummary)))
  const setTaskCountSummary = useTaskStore(state => state.setTaskCountSummary)
  const [groupCountsMap, setGroupCountsMap] = useState<Map<string, number>>(new Map())
  const [searchParams] = useSearchParams()
  const [selectedFilter, setSelectedFilter] = useState<string>(tasksMainNavItems[0].name)

  // Watchers
  useEffect(() => {
    if (searchParams.has('filter') && searchParams.get('filter') !== ListTaskInputFilterType.Group) {
      const filteredNavItem = tasksMainNavItems.find(i => i.queryFilter === searchParams.get('filter'))
      if (filteredNavItem && filteredNavItem.name !== selectedFilter) {
        setSelectedFilter(filteredNavItem.name)
      }
    }

    if (searchParams.has('filter') && searchParams.get('filter') === ListTaskInputFilterType.Group && searchParams.has('groupId')) {
      const filteredGroup = authenticatedUserInfo?.listGroupsForUser.groups.find(g => g?.id === searchParams.get('groupId'))
      if (filteredGroup && filteredGroup.name !== selectedFilter) {
        setSelectedFilter(filteredGroup.name)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!loading && !error && data && data.getTaskCountSummary) {
      setTaskCountSummary(data.getTaskCountSummary)

      // Construct a map of ids and counts from data.getTaskCountSummary.groups
      if (data.getTaskCountSummary.groups.length > 0) {
        const groupMap = new Map<string, number>()
        data.getTaskCountSummary.groups.forEach(g => {
          groupMap.set(g?.id || '', g?.count || 0)
        })

        setGroupCountsMap(groupMap)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, error, loading])

  return (
    <aside className="hidden lg:order-first lg:block lg:flex-shrink-0">
      <div className="relative flex h-full w-80 flex-col overflow-y-auto border-r border-gray-200 p-5 bg-white">
        <CreateTaskModal />

        <nav className="mt-8 space-y-1" aria-label="Sidebar">
          {tasksMainNavItems.map((item) => (
            <button
              key={item.name}
              className={classNames(
                'flex w-full',
                'text-gray-600 hover:bg-gray-100 hover:text-gray-900',
                'group flex items-center px-3 py-2 text-sm font-medium rounded-md',
                { 'bg-gray-200 text-gray-900': item.name === selectedFilter }
              )}
              onClick={() => {
                navigate(`${Routes.Tasks}?filter=${item.queryFilter}`)
                setSelectedFilter(item.name)
              }}
            >
              <item.icon
                className={classNames(
                  'group-hover:text-gray-500',
                  'flex-shrink-0 -ml-1 mr-3 h-6 w-6',
                  `${item.name === selectedFilter ? 'text-gray-900' : 'text-gray-400'}`
                )}
                aria-hidden="true"
              />
              <span className="truncate">{item.name}</span>
              {taskCounts.hasOwnProperty(item.queryFilter) && taskCounts[item.queryFilter] > 0 && (
                <span
                  className={classNames(
                    'text-gray-600 group-hover:bg-gray-200',
                    'ml-auto inline-block py-0.5 px-3 text-xs rounded-full',
                    `${item.name === selectedFilter ? 'bg-white' : 'bg-gray-100'}`
                  )}
                >
                  {taskCounts[item.queryFilter]}
                </span>
              )}
            </button>
          ))}
        </nav>

        {authenticatedUserInfo?.listGroupsForUser && authenticatedUserInfo.listGroupsForUser.count > 0 && authenticatedUserInfo.listGroupsForUser.count <= 10 && (
          <div className="mt-10">
            <h3 className="px-3 text-xs font-medium text-gray-500">
              My groups
            </h3>

            <div className="mt-2">
              {authenticatedUserInfo?.listGroupsForUser.groups.map((g) => {
                if (!g) return null

                return (
                  <button
                    key={g.id}
                    className={classNames(
                      'flex w-full',
                      'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                      'group flex items-center px-3 py-2 text-sm font-medium rounded-md',
                      { 'bg-gray-200 text-gray-900': g.name === selectedFilter }
                    )}
                    onClick={() => {
                      navigate(`${Routes.Tasks}?filter=${ListTaskInputFilterType.Group}&groupId=${g.id}`)
                      setSelectedFilter(g.name)
                    }}
                  >
                    <span className="truncate">{g.name}</span>
                    {/* @ts-ignore */}
                    {groupCountsMap.has(g.id) && groupCountsMap.get(g.id) > 0 && (
                      <span
                        className={classNames(
                          'text-gray-600 group-hover:bg-gray-200',
                          'ml-auto inline-block py-0.5 px-3 text-xs rounded-full',
                          `${g.name === selectedFilter ? 'bg-white' : 'bg-gray-100'}`
                        )}
                      >
                        {groupCountsMap.get(g.id)}
                      </span>
                    )}
                  </button>
                )
              })}
            </div>
          </div>
        )}

        {authenticatedUserInfo?.listGroupsForUser && authenticatedUserInfo.listGroupsForUser.count > 10 && (
          <div className="mt-10">
            <h3 className="px-3 text-xs font-medium text-gray-500">
              My groups
            </h3>

            <div className="mt-2 px-3">
              <NavBarGroupSelect
                onChange={(s) => {
                  if (s.id === noGroup.id) {
                    navigate(`${Routes.Tasks}?filter=${ListTaskInputFilterType.All}`)
                    setSelectedFilter('All tasks')

                    return
                  }

                  navigate(`${Routes.Tasks}?filter=${ListTaskInputFilterType.Group}&groupId=${s.id}`)
                  setSelectedFilter(s.name)
                }}
              />
            </div>
          </div>
        )}
      </div>
    </aside>
  )
}