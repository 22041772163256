import create from 'zustand'
import { isToday, add, isBefore } from 'date-fns'
import { ListTaskInputFilterType, ListTaskResponseItem, TaskCountSummaryResponse, IdName } from '../types/gql/graphql'
import { AdminFilter } from '../pages'

type TaskFilters = {
  filter: ListTaskInputFilterType
  groupId?: string
  assignedToOthers?: boolean
}

interface TaskStore {
  // State
  authenticatedUserId?: string
  taskCountSummary: TaskCountSummaryResponse
  tasksToShow: ListTaskResponseItem[]
  currentFilters?: TaskFilters
  assignableUsers: IdName[]

  // Setters
  setAuthenticatedUserId: (userId: string) => void
  setTaskCountSummary: (data: TaskCountSummaryResponse) => void
  setTasksToShow: (tasks: ListTaskResponseItem[]) => void
  addCreatedTask: (task: ListTaskResponseItem) => void
  updateTask: (task: ListTaskResponseItem) => void
  removeTask: (id: string) => void
  setCurrentFilters: (searchParams: URLSearchParams) => void
  setAssignableUsers: (users: IdName[]) => void
}

export const useTaskStore = create<TaskStore>()((set, get) => ({
  authenticatedUserId: undefined,
  taskCountSummary: {
    ALL: 0,
    DUETODAY: 0,
    NEXTSEVENDAYS: 0,
    groups: [],
  },
  tasksToShow: [],
  currentFilters: undefined,
  activeFilter: undefined,
  assignedToOthers: false,
  assignableUsers: [],

  /**
   * Called as soon as the user data is fetched from the server.
   * We need the authenticated user ID to know which tasks to show.
   * 
   * @param userId The database ID of the authenticated user
   */
  setAuthenticatedUserId: (userId) => {
    set({ authenticatedUserId: userId })
  },

  setTaskCountSummary(data: TaskCountSummaryResponse) {
    set(() => ({ taskCountSummary: data }))
  },

  /**
   * Store an array of tasks that need to be displayed.
   * 
   * @param tasks An array of tasks to show in the UI
   */
  setTasksToShow(tasks) {
    set(() => ({ tasksToShow: tasks }))
  },

  /**
   * Determines whether a task needs to be displayed in the UI based on
   * the active filter and adds it to the top of the list.
   * 
   * @param task A task that was just created and needs to be added to the UI
   */
  addCreatedTask(task) {
    const userId = get().authenticatedUserId
    const currentFilters = get().currentFilters
    if (!userId || !currentFilters || currentFilters.filter === ListTaskInputFilterType.Completed) {
      return
    }

    let shouldAddToList = false

    if (currentFilters.filter === ListTaskInputFilterType.All) {
      shouldAddToList = true
    }

    if (currentFilters.filter === ListTaskInputFilterType.Duetoday && task.dueDate && isToday(parseInt(task.dueDate))) {
      shouldAddToList = true
    }

    if (currentFilters.filter === ListTaskInputFilterType.Nextsevendays && task.dueDate) {
      const today = isToday(parseInt(task.dueDate))
      const nextSevenDays = isBefore(parseInt(task.dueDate), add(new Date(), { days: 7 }))
      shouldAddToList = today || nextSevenDays
    }

    if (currentFilters.filter === ListTaskInputFilterType.Group && task.group && task.group.id === currentFilters.groupId) {
      shouldAddToList = true
    }

    if (task.assignedTo?.id !== userId && !currentFilters.assignedToOthers) {
      shouldAddToList = false
    }

    if (!shouldAddToList) {
      return
    }

    set((state) => ({
      tasksToShow: [task, ...state.tasksToShow],
    }))

    // Increase the count of the task in the task count summary based on the filter
    if (currentFilters && !currentFilters.assignedToOthers && task.assignedTo?.id === userId) {
      const taskCountSummary = get().taskCountSummary

      switch (currentFilters.filter) {
        case ListTaskInputFilterType.All:
          set((state) => ({
            taskCountSummary: {
              ...taskCountSummary,
              ALL: state.taskCountSummary.ALL + 1,
            }
          }))
          break;

        case ListTaskInputFilterType.Duetoday:
          set((state) => ({
            taskCountSummary: {
              ...taskCountSummary,
              DUETODAY: state.taskCountSummary.DUETODAY + 1,
            }
          }))
          break;

        case ListTaskInputFilterType.Nextsevendays:
          set((state) => ({
            taskCountSummary: {
              ...taskCountSummary,
              NEXTSEVENDAYS: state.taskCountSummary.NEXTSEVENDAYS + 1,
            }
          }))
          break;

        // TODO: Handle group filter

        default:
          break;
      }
    }
  },

  /**
   * Updates the task that was edited in the tasksToShow array.
   * 
   * @param task The updated task record.
   */
  updateTask(task) {
    const tasks = [...get().tasksToShow]
    const index = tasks.findIndex((t) => t.id === task.id)
    if (index === -1) {
      return
    }

    tasks[index] = task
    set(() => ({ tasksToShow: tasks }))
  },

  /**
   * Removes a task from the tasksToShow array.
   * 
   * @param id The ID of the task to remove
   */
  removeTask(id) {
    // Remove the task from the store
    set((state) => ({
      tasksToShow: state.tasksToShow.filter((task) => task.id !== id),
    }))

    // Reduce the count of the task in the task count summary based on the filter
    const currentFilters = get().currentFilters
    if (currentFilters && !currentFilters.assignedToOthers) {
      const taskCountSummary = get().taskCountSummary

      switch (currentFilters.filter) {
        case ListTaskInputFilterType.All:
          set((state) => ({
            taskCountSummary: {
              ...taskCountSummary,
              ALL: state.taskCountSummary.ALL - 1,
            }
          }))
          break;

        case ListTaskInputFilterType.Duetoday:
          set((state) => ({
            taskCountSummary: {
              ...taskCountSummary,
              DUETODAY: state.taskCountSummary.DUETODAY - 1,
            }
          }))
          break;

        case ListTaskInputFilterType.Nextsevendays:
          set((state) => ({
            taskCountSummary: {
              ...taskCountSummary,
              NEXTSEVENDAYS: state.taskCountSummary.NEXTSEVENDAYS - 1,
            }
          }))
          break;

        // TODO: Handle group filter

        default:
          break;
      }
    }
  },

  /**
   * Extracts the query params into a more readable object.
   * 
   * @param searchParams The query params from the /tasks page
   */
  setCurrentFilters(searchParams) {
    // We are always guaranteed to get a filter param
    const filter = searchParams.get('filter') as ListTaskInputFilterType

    set(() => ({
      currentFilters: {
        filter,
        groupId: searchParams.has('groupId') ? searchParams.get('groupId') as string : undefined,
        assignedToOthers: searchParams.has('adminFilter') && searchParams.get('adminFilter') === AdminFilter.AssignedToOthers,
      },
    }))
  },

  /**
   * Stores a list of users that the authenticated user can assign tasks to.
   * 
   * @param users An array of users to store in the store
   */
  setAssignableUsers(users) {
    set(() => ({ assignableUsers: users }))
  },
}))
