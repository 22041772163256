import { useState, useMemo } from 'react'
import { PlusIcon } from '@heroicons/react/24/solid'
import { useMutation } from '@apollo/client'
import { toast } from 'react-toastify'
import { Modal, Button, Input, PhoneNumberInput, Switch } from "../../../../components/elements"
import { User } from '../Users'
import { CREATE_USER } from '../../../../graphql'

// Local types
type CreateUserModalProps = {
  onCreated: (user: User) => void
}

export function CreateUserModal({ onCreated }: CreateUserModalProps) {
  // Hooks
  const [createUser, { loading }] = useMutation(CREATE_USER)

  // Component state
  const [firstName, setFirstName] = useState<string>('')
  const [lastName, setLastName] = useState<string>('')
  const [mobileNumber, setMobileNumber] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [jobTitle, setJobTitle] = useState<string>('')
  const [isOrgAdmin, setIsOrgAdmin] = useState<boolean>(false)

  // create a variable called disabled using useMemo that is a boolean, and is true if the length of firstName is 0, or the length of lastName is 0, or the length of mobileNumber is 0, or the length of email is 0, or the length of jobTitle is 0
  const disabled = useMemo<boolean>(() => {
    return firstName.length === 0 || lastName.length === 0 || mobileNumber.length === 0
  }, [firstName, lastName, mobileNumber])


  // Functions
  const handleSubmission = async (closeModal: () => void) => {
    try {
      const response = await createUser({
        variables: {
          input: {
            firstName,
            lastName,
            phoneNumber: mobileNumber,
            email,
            jobTitle,
            isAdmin: isOrgAdmin
          }
        }
      })

      if (response.errors) {
        toast.error('An error occurred while creating the user. Please try again or contact support.')
        return
      }

      if (response.data?.createUser) {
        closeModal()
        setFirstName('')
        setLastName('')
        setMobileNumber('')
        setEmail('')
        setJobTitle('')
        setIsOrgAdmin(false)
        toast.success('User successfully created.')
        onCreated({
          id: response.data.createUser.id,
          fullName: `${firstName} ${lastName}`,
          mobileNumber,
          groups: [],
          extraGroupCount: 0,
          isOrgAdmin,
          isNew: true
        })
      }
    } catch (error) {
      toast.error('An error occurred while creating the user. Please try again or contact support.')
    }
  }

  return (
    <Modal
      trigger={(
        <Button
          text="User"
          icon={{
            element: <PlusIcon />,
            position: 'left'
          }}
          onClick={() => { }}
        />
      )}
      title="Create a user"
      description="Please ensure that the user is able to receive SMS-based OTPs to this number before you create their account. You need to add this user to the relevant groups once the account is created."
      primaryButton={{
        text: 'Create',
        disabled,
        loading,
        onClick: (closeModal) => {
          handleSubmission(closeModal)
        }
      }}
    >
      <form className="flex flex-col gap-6 my-6">
        <div className="flex flex-col gap-4 sm:flex-row">
          <div className="flex-grow">
            <Input
              label="First name"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              required
            />
          </div>
          <div className="flex-grow">
            <Input
              label="Last name"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              required
            />
          </div>
        </div>
        <div className="flex flex-col gap-4 sm:flex-row">
          <div className="sm:w-1/2">
            <PhoneNumberInput
              label="Phone number"
              onChange={setMobileNumber}
            />
          </div>
          <div className="sm:w-1/2">
            <Input
              type="email"
              label="Email"
              hint="Optional"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
        </div>
        <div className="pr-2 sm:w-1/2">
          <Input
            label="Job title"
            hint="Optional"
            value={jobTitle}
            onChange={(e) => setJobTitle(e.target.value)}
          />
        </div>
        <div className="flex gap-4 items-center justify-between p-4 bg-gray-50 rounded-lg">
          <div className="flex flex-col gap-1 text-sm">
            <span className="font-medium">Grant Admin Module access?</span>
            <p className="text-xs text-gray-600">
              If you grant admin access, this user will be able to access the Admin Module to create/edit groups,
              users, etc. and modify organisation settings. You can revoke admin access at any time.
            </p>
          </div>
          <Switch onChange={setIsOrgAdmin} />
        </div>
      </form>
    </Modal>
  )
}
