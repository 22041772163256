import { graphql } from '../types/gql'

export const LIST_USERS_IN_GROUP = graphql(`
  query listUsersInGroup($groupId: String!) {
    listUsersInGroup(groupId: $groupId) {
      id
      name
      jobTitle
      phoneNumber
      isAdmin
    }
  }
`)

