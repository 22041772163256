import { Frame, UnderConstruction } from "../../components/partials";
import { useTitle } from "../../hooks";

export function PnLPage() {
  // Set page title
  useTitle('P&L')

  return (
    <Frame module="Profit & Loss">
      <div className="flex items-center justify-center w-full h-full">
        <UnderConstruction />
      </div>
    </Frame>
  )
}
