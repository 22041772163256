import { useState, useEffect } from 'react'
import { TrashIcon } from '@heroicons/react/24/solid'
import Skeleton from 'react-loading-skeleton'
import { useQuery, useMutation } from '@apollo/client'
import { toast } from 'react-toastify'
import { Select, Button, Switch, Modal } from '../../../../components/elements'
import { UserSelect } from '../../tasks/UserSelect'
import {
  LIST_USERS_IN_GROUP, ADD_USER_TO_GROUP, REMOVE_USER_FROM_GROUP, GRANT_USER_ROLE_FOR_GROUP,
} from '../../../../graphql'
import { IdName, ListUsersInGroupItem, PermissionRole } from '../../../../types/gql/graphql'
import { useGlobalStore } from '../../../../store'

// Local types
type EditGroupMembersProps = {
  id: string
  onMembersChanged: (admins: string[], userCount: number) => void
}

type UserItem = ListUsersInGroupItem | null

export function EditGroupMembers({ id, onMembersChanged }: EditGroupMembersProps) {
  // Component state
  const authenticatedUserInfo = useGlobalStore(state => state.authenticatedUserInfo)
  const authenticatedUserFullName = useGlobalStore(state => state.authenticatedUserFullName)
  const [users, setUsers] = useState<UserItem[]>([])
  const [userDataFetched, setUserDataFetched] = useState<boolean>(false)
  const [userToAdd, setUserToAdd] = useState<IdName>({ id: authenticatedUserInfo?.getAuthenticatedUser.id || '', name: authenticatedUserFullName || '' })
  const [userToAddRole, setUserToAddRole] = useState<PermissionRole>(PermissionRole.Member)

  // Hooks
  const { loading, error, data } = useQuery(LIST_USERS_IN_GROUP, {
    variables: { groupId: id },
    fetchPolicy: 'network-only'
  })
  const [addUserToGroup, { loading: addUserLoading }] = useMutation(ADD_USER_TO_GROUP)
  const [removeUserFromGroup, { loading: removeUserLoading }] = useMutation(REMOVE_USER_FROM_GROUP)
  const [grantUserRoleForGroup] = useMutation(GRANT_USER_ROLE_FOR_GROUP)

  // Watchers
  useEffect(() => {
    if (!error && !loading && data && data.listUsersInGroup) {
      setUsers(data.listUsersInGroup)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  useEffect(() => {
    if (!userDataFetched) {
      setUserDataFetched(true)
    }

    if (userDataFetched) {
      const admins = users.filter(user => user?.isAdmin).map(user => user?.name || '')
      onMembersChanged(admins, users.length)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users])

  // Functions
  const handleAddUserToGroup = async () => {
    // Is the user already in the group?
    const userIndex = users.findIndex(user => user?.id === userToAdd.id)
    if (userIndex >= 0) {
      toast.info('User is already in the group')
      return
    }

    try {
      const response = await addUserToGroup({
        variables: {
          groupId: id,
          userId: userToAdd.id,
          role: userToAddRole
        }
      })

      if (response.errors || !response.data?.addUserToGroup) {
        toast.error('Unable to add the user to the group. Please try again or contact support.')
        return
      }

      setUsers([response.data.addUserToGroup, ...users])
      toast.success(`${userToAdd.name} added to group`)
    } catch (error) {
      toast.error('Unable to add the user to the group. Please try again or contact support.')
    }
  }

  const handleRemoveUserFromGroup = async (userId: string): Promise<boolean> => {
    try {
      const response = await removeUserFromGroup({
        variables: {
          groupId: id,
          userId
        }
      })

      if (response.errors || !response.data?.removeUserFromGroup) {
        toast.error('Unable to remove the user from the group. Please try again or contact support.')
        return Promise.resolve(false)
      }

      // Remove the user from the users list
      const updatedUsers = users.filter(user => user?.id !== userId)
      setUsers(updatedUsers)

      toast.success('User removed from group')

      return Promise.resolve(true)
    } catch (error) {
      toast.error('Unable to remove the user from the group. Please try again or contact support.')
      return Promise.resolve(false)
    }
  }

  const updateUserRole = (userId: string, isAdmin: boolean) => {
    const userIndex = users.findIndex(user => user?.id === userId)
    if (userIndex < 0 || !users[userIndex]) {
      return
    }

    const updatedUsers = [...users]
    const user = updatedUsers[userIndex]
    if (user) {
      user.isAdmin = isAdmin
      setUsers(updatedUsers)
    }
  }

  const handleRoleToggle = async (userId: string, isAdmin: boolean) => {
    const userIndex = users.findIndex(user => user?.id === userId)
    if (!users[userIndex] || users[userIndex]?.isAdmin === isAdmin) {
      return
    }
    updateUserRole(userId, isAdmin)

    try {
      const response = await grantUserRoleForGroup({
        variables: {
          groupId: id,
          userId,
          role: isAdmin ? PermissionRole.Admin : PermissionRole.Member
        }
      })

      if (response.errors || !response.data) {
        toast.error('Unable to update the user\'s role. Please try again or contact support.')
        updateUserRole(userId, !isAdmin)
        return
      }

      toast.success(isAdmin ? 'Admin access granted' : 'Admin access revoked')
    } catch (error) {
      toast.error('Unable to update the user\'s role. Please try again or contact support.')
      updateUserRole(userId, !isAdmin)
    }
  }

  return (
    <div className="flex flex-col gap-8">
      {loading ? (
        <div className="flex items-end gap-4">
          <Skeleton className="w-[421px] h-10" />
          <Skeleton className="w-[108px] h-10" />
          <Skeleton className="w-16 h-10" />
        </div>
      ) : (
        <div className="flex items-end gap-4">
          <div className="flex-grow">
            <UserSelect
              label="Add user to group"
              value={userToAdd}
              onChange={setUserToAdd}
            />
          </div>
          <Select
            items={[
              { id: PermissionRole.Member, name: 'Member' },
              { id: PermissionRole.Admin, name: 'Admin' }
            ]}
            onChange={(i) => { setUserToAddRole(i.id === PermissionRole.Admin ? PermissionRole.Admin : PermissionRole.Member) }}
          />
          <Button
            text="Add"
            loading={addUserLoading}
            onClick={handleAddUserToGroup}
          />
        </div>
      )}

      <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
        <table className="min-w-full divide-y divide-gray-300">
          <thead className="bg-gray-50">
            <tr>
              <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                Name
              </th>
              <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                Mobile Number
              </th>
              <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                Admin Access
              </th>
              <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                <span className="sr-only">Remove from group</span>
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            {loading && [1, 2, 3, 4, 5].map((i) => (
              <tr key={i}>
                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                  <Skeleton className="w-full h-8" />
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500"><Skeleton className="w-full h-8" /></td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                  <Skeleton className="w-full h-8" />
                </td>
                <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                  <Skeleton className="w-full h-8" />
                </td>
              </tr>
            ))}

            {!loading && !error && users.length === 0 && (
              <tr>
                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-6">
                  No users in this group
                </td>
              </tr>
            )}

            {!loading && !error && users.length > 0 && users.map((u) => {
              if (!u) {
                return null
              }

              return (
                <tr key={u.id}>
                  <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                    {u.name}
                    <span className="block mt-1 text-xs text-gray-500">{u.jobTitle || 'Title unknown'}</span>
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{u.phoneNumber}</td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    <Switch checked={u.isAdmin} onChange={(v) => { handleRoleToggle(u.id, v) }} />
                  </td>
                  <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                    <Modal
                      variant="error"
                      trigger={(
                        <Button
                          variant="outline"
                          size="sm"
                          icon={{
                            element: <TrashIcon />,
                            position: 'center',
                          }}
                          onClick={() => { }}
                        />
                      )}
                      title="Remove user from group?"
                      description={`Tasks already assigned to ${u.name} in this group will be unaffected by this change.`}
                      primaryButton={{
                        text: 'Remove from group',
                        loading: removeUserLoading,
                        onClick: async (closeModal) => {
                          const isRemoved = await handleRemoveUserFromGroup(u.id)
                          if (isRemoved) {
                            closeModal()
                          }
                        }
                      }}
                    />
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}
