import classNames from 'classnames'
import { ThreeDots } from 'react-loader-spinner'

const variantTextColorMap: { [key: string]: string } = {
  primary: '#fff',
  secondary: '#4338ca',
  outline: '#374151',
  error: '#fff',
  success: '#fff'
}

type ButtonProps = React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> & {
  text?: string
  size?: 'sm' | 'md' | 'lg' | 'xl' | '2xl'
  fluid?: boolean
  variant?: 'primary' | 'secondary' | 'outline' | 'error' | 'success'
  icon?: {
    element: JSX.Element,
    position: 'left' | 'center' | 'right'
  }
  loading?: boolean
}

export function Button({
  type = 'button',
  disabled,
  text,
  size = 'lg',
  fluid = false,
  variant = 'primary',
  icon,
  loading = false,
  ...props
}: ButtonProps) {
  const classes = classNames(
    'inline-flex items-center border font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2',

    // Size-specific styles
    { 'rounded px-2.5 py-1.5 text-xs': size === 'sm' },
    { 'px-3 py-2 text-sm leading-4': size === 'md' },
    { 'px-4 py-2 text-sm': size === 'lg' },
    { 'px-4 py-2 text-base': size === 'xl' },
    { 'px-6 py-3 text-base': size === '2xl' },

    // Fluid style
    { 'flex justify-center w-full': fluid },

    // Variant-specific styles
    { 'bg-indigo-600 text-white border-transparent shadow-sm hover:bg-indigo-700 disabled:bg-indigo-400': variant === 'primary' },
    { 'bg-indigo-100 text-indigo-700 border-transparent hover:bg-indigo-200 disabled:bg-indigo-50': variant === 'secondary' },
    { 'border-gray-300 bg-white text-gray-700 shadow-sm hover:bg-gray-50 disabled:bg-gray-50': variant === 'outline' },
    { 'bg-red-600 text-white border-transparent shadow-sm hover:bg-red-700 disabled:bg-red-400': variant === 'error' },
    { 'bg-green-600 text-white border-transparent shadow-sm hover:bg-green-700 disabled:bg-green-400': variant === 'success' },

    // Loading-styles
    { 'bg-indigo-400': variant === 'primary' && loading },
    { 'bg-indigo-50': variant === 'secondary' && loading },
    { 'bg-gray-50': variant === 'outline' && loading },
    { 'bg-red-400': variant === 'error' && loading },
    { 'bg-green-400': variant === 'success' && loading },
  )

  const iconClasses = classNames(
    { 'child:h-4 child:w-4': size === 'sm' || size === 'md' },
    { 'child:h-5 child:w-5': size === 'lg' || size === 'xl' || size === '2xl' },

    // Loading state
    { 'opacity-0': loading }
  )

  return (
    <button
      type={type}
      className={classes}
      disabled={disabled}
      {...props}
    >
      {icon && icon.position === 'left' && (
        <div className={`-ml-1 mr-2 ${iconClasses}`}>
          {icon.element}
        </div>
      )}

      <div className={classNames({ 'relative': loading })}>
        {text && <span className={classNames({ 'opacity-0': loading })}>{text}</span>}

        {icon && icon.position === 'center' && (
          <div className={iconClasses}>
            {icon.element}
          </div>
        )}

        {loading && (
          <div className="absolute" style={{ top: 'calc(50% - 6px)', left: 'calc(50% - 16px)' }}>
            <ThreeDots
              color={variantTextColorMap[variant]}
              width={32}
              height={12}
            />
          </div>
        )}
      </div>

      {icon && icon.position === 'right' && (
        <div className={`ml-2 -mr-1 ${iconClasses}`}>
          {icon.element}
        </div>
      )}
    </button>
  )
}
