import { Frame, UnderConstruction } from "../../components/partials";
import { useTitle } from "../../hooks";

export function ChecklistsPage() {
  // Set page title
  useTitle('Checklists')

  return (
    <Frame module="Checklists">
      <div className="flex items-center justify-center w-full h-full">
        <UnderConstruction />
      </div>
    </Frame>
  )
}
