import create from 'zustand'
import { User } from 'firebase/auth'
import { GetAuthenticatedUserInfoQuery, ListGroupsForUserQuery } from '../types/gql/graphql'

interface GlobalStore {
  // State
  appLoading: boolean
  authenticatedUserInfo: GetAuthenticatedUserInfoQuery | null
  authenticatedUserFullName: string | null
  isOrgAdminOrGroupAdmin: boolean

  // Setters
  setAppLoaded: (firebaseUser?: User) => void
  setAuthenticatedUserInfo: (data: GetAuthenticatedUserInfoQuery) => void
  updateAuthenticatedUserGroups: (data?: ListGroupsForUserQuery) => void
}

export const useGlobalStore = create<GlobalStore>()((set, get) => ({
  appLoading: true,
  authenticatedUserInfo: null,
  authenticatedUserFullName: null,
  isOrgAdminOrGroupAdmin: false,

  /**
   * Called in App.tsx once Firebase confirms the user's authentication
   * status.
   */
  setAppLoaded() {
    set(() => ({ appLoading: false }))
  },

  setAuthenticatedUserInfo(data) {
    set(() => ({
      authenticatedUserInfo: data,
      authenticatedUserFullName: `${data.getAuthenticatedUser.firstName} ${data.getAuthenticatedUser.lastName}`,
    }))

    if (data.getUserRolesSummary.isOrgAdmin || data.getUserRolesSummary.isGroupAdmin) {
      set(() => ({ isOrgAdminOrGroupAdmin: true }))
    }
  },

  /**
   * Partially updates the authenticated user object with the latest
   * list of groups. We typically call this when groups have been
   * added or removed for the authenticated user.
   * 
   * @param data The response from the GraphQL query
   */
  updateAuthenticatedUserGroups(data) {
    const userInfo = get().authenticatedUserInfo
    if (!userInfo || !data) {
      return
    }

    set(() => ({
      authenticatedUserInfo: {
        ...userInfo,
        listGroupsForUser: data.listGroupsForUser,
      },
    }))
  }
}))
