import { useState } from 'react'
import { useMutation, useLazyQuery } from '@apollo/client'
import { toast } from 'react-toastify'
import { Input, Button } from '../../../../components/elements'
import { EDIT_GROUP, DELETE_GROUP, LIST_GROUPS_FOR_USER } from '../../../../graphql'
import { useGlobalStore } from '../../../../store'

type EditGroupDetailsProps = {
  id: string
  name: string
  onUpdate: (id: string, name: string) => void
  onDelete: (id: string) => void
}

export function EditGroupDetails({ id, name, onUpdate, onDelete }: EditGroupDetailsProps) {
  // Hooks
  const [editGroup, { loading: editLoading }] = useMutation(EDIT_GROUP)
  const [deleteGroup, { loading: deleteLoading }] = useMutation(DELETE_GROUP)
  const [listGroupsForUser] = useLazyQuery(LIST_GROUPS_FOR_USER, { fetchPolicy: 'network-only' })

  // Component state
  const updateAuthenticatedUserGroups = useGlobalStore((state) => state.updateAuthenticatedUserGroups)
  const [actualName, setActualName] = useState<string>(name)
  const [deleteInputName, setDeleteInputName] = useState<string>('')

  // Edit the group name
  const handleEdit = async () => {
    if (actualName === name || actualName.length === 0) {
      return false
    }

    try {
      const response = await editGroup({ variables: { editGroupId: id, name: actualName } })

      if (response.errors || !response.data?.editGroup) {
        toast.error('An error occurred while editing the group. Please try again.')
        return false
      }

      // Re-fetch the authenticated user's groups
      const groupsResponse = await listGroupsForUser()
      if (groupsResponse.error || !groupsResponse.data?.listGroupsForUser) {
        toast.warn('Unable to refresh your groups. Please reload this page.')
      }
      updateAuthenticatedUserGroups(groupsResponse.data)

      toast.success('Group edited successfully.')
      onUpdate(id, actualName)
    } catch (error) {
      toast.error('An error occurred while editing the group. Please try again.')
    }
  }

  // Delete the group
  const handleDelete = async () => {
    if (deleteInputName !== actualName) {
      return false
    }

    try {
      const response = await deleteGroup({ variables: { id } })

      if (response.errors || !response.data?.deleteGroup) {
        toast.error('An error occurred while deleting the group. Please try again.')
        return false
      }

      // Re-fetch the authenticated user's groups
      const groupsResponse = await listGroupsForUser()
      if (groupsResponse.error || !groupsResponse.data?.listGroupsForUser) {
        toast.warn('Unable to refresh your groups. Please reload this page.')
      }
      updateAuthenticatedUserGroups(groupsResponse.data)

      toast.success('Group deleted successfully.')
      onDelete(id)
    } catch (error) {
      toast.error('An error occurred while deleting the group. Please try again.')
    }
  }

  return (
    <div className="flex flex-col gap-8">
      <div className="flex flex-col gap-4">
        <Input
          label="Group name"
          value={actualName}
          onChange={(e) => setActualName(e.target.value)}
        />
        <div>
          <Button
            type="submit"
            text="Save changes"
            disabled={actualName === name || actualName.length === 0}
            loading={editLoading}
            onClick={handleEdit}
          />
        </div>
      </div>

      <div className="border border-dashed border-red-500 bg-red-50 p-4 rounded-lg">
        <div className="flex flex-col gap-1">
          <span className="block font-medium">Danger Zone</span>
          <p className="text-xs">
            If you wish to delete this group, please re-enter the group name below to continue. This action
            is applied immediately and can only be undone by contacting support.
          </p>
          <div className="flex flex-col gap-3 mt-5">
            <Input
              label="Re-enter group name"
              placeholder={actualName}
              value={deleteInputName}
              onChange={(e) => setDeleteInputName(e.target.value)}
            />
            <div>
              <Button
                variant="error"
                text="Delete"
                disabled={deleteInputName !== actualName}
                loading={deleteLoading}
                onClick={handleDelete}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
