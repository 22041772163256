import { graphql } from '../types/gql'

export const ADD_USER_TO_GROUP = graphql(`
  mutation addUserToGroup($groupId: String!, $userId: String!, $role: PermissionRole) {
    addUserToGroup(groupId: $groupId, userId: $userId, role: $role) {
      id
      name
      jobTitle
      phoneNumber
      isAdmin
    }
  }
`)
