import { graphql } from '../types/gql'

export const CREATE_TASK = graphql(`
  mutation createTask($input: CreateTaskInput!) {
    createTask(input: $input) {
      id
      title
      description
      dueDate
      assignedTo {
        id
        name
      }
      group {
        id
        name
      }
      createdBy {
        id
        name
      }
    }
  }
`)
