import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { isValidPhoneNumber } from 'react-phone-number-input'
import { RecaptchaVerifier, signInWithPhoneNumber, ConfirmationResult } from 'firebase/auth'
import OTPInput from 'react-otp-input'
import { toast } from 'react-toastify'
import { useTitle } from "../hooks"
import { Routes } from '../types'
import { PhoneNumberInput, Button } from '../components/elements'
import { firebaseAuth } from '../firebase'
import { useGlobalStore } from '../store'
import { Logo } from '../static-assets'

export function LogInPage() {
  // Set page title
  useTitle('Log in')

  // Hooks
  const navigate = useNavigate()

  // Component state
  const authenticatedUserInfo = useGlobalStore(state => state.authenticatedUserInfo)
  const [mobileNumber, setMobileNumber] = useState<string>('+91')
  const [otp, setOtp] = useState<string>('')
  const [firebaseRecaptchaVerifier, setFirebaseRecaptchaVerifier] = useState<RecaptchaVerifier>()
  const [firebaseConfirmationResult, setFireaseConfirmationResult] = useState<ConfirmationResult>()
  const [requestingOTP, setRequestingOTP] = useState<boolean>(false)
  const [requestedOTP, setRequestedOTP] = useState<boolean>(false)
  const [authenticating, setAuthenticating] = useState<boolean>(false)

  // On page load
  useEffect(() => {
    if (authenticatedUserInfo) {
      navigate(`${Routes.Tasks}?filter=ALL`)
    }

    // Set the invisible reCAPTCHA verifier
    setFirebaseRecaptchaVerifier(
      new RecaptchaVerifier('sign-in-button', {
        'size': 'invisible',
        'callback': () => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
        }
      }, firebaseAuth)
    )

    // Clear the ID token from local storage
    localStorage.removeItem('firebaseIdToken')
    localStorage.removeItem('firebaseIdTokenExpiration')

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Send OTP to phone number
  const sendOTP = () => {
    if (!firebaseRecaptchaVerifier) {
      toast.error('Unknown error. Please reload and try again.')
      return
    }

    if (!isValidPhoneNumber(mobileNumber)) {
      toast.error('Please enter a valid mobile number.')
      return
    }

    setRequestingOTP(true)

    signInWithPhoneNumber(firebaseAuth, mobileNumber, firebaseRecaptchaVerifier)
      .then((result) => {
        setFireaseConfirmationResult(result)
        setRequestedOTP(true)
      })
      .catch((error) => {
        // TODO: Reset the reCAPTCHA verifier here

        if (error.message.includes('invalid-phone-number')) {
          toast.error('Invalid phone number. Please check your phone number and try again.')
          return
        }

        toast.error('An unknown error occurred. Please try again or contact support if this issue persists.')
      })
      .finally(() => {
        setRequestingOTP(false)
      })
  }

  // Authenticates the user and redirects them to the tasks page
  const verifyAndLogIn = () => {
    if (!firebaseConfirmationResult) {
      toast.error('Unable to verify your login attempt. Please reload the page and try again.')
      return
    }

    setAuthenticating(true)
    firebaseConfirmationResult.confirm(otp)
      .then(() => {
        // Do nothing, redirection occurs in the App.tsx component
      })
      .catch((error) => {
        if (error.message.includes('auth/invalid-verification-code')) {
          toast.error('Invalid OTP. Please try again.')
          return
        }

        toast.error(error.message)
      })
      .finally(() => {
        setAuthenticating(false)
      })
  }

  return (
    <div className="h-screen flex flex-col justify-center py-12 sm:px-6 lg:px-8 bg-gray-50">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <Logo fill="fill-indigo-600" height={12} />
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Streamline</h2>
      </div>

      <div className="mt-8 mx-4 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          {!requestedOTP && (
            <form className="space-y-6" onSubmit={(e) => { e.preventDefault(); sendOTP(); }}>
              <PhoneNumberInput
                label="Mobile number"
                onChange={setMobileNumber}
              />

              <div>
                <Button
                  id="sign-in-button"
                  type="submit"
                  text="Get OTP"
                  loading={requestingOTP}
                  fluid
                />
              </div>
            </form>
          )}

          {requestedOTP && (
            <div className="space-y-6">
              <div>
                <span className="block text-sm font-medium text-gray-700">
                  OTP
                </span>
                <OTPInput
                  numInputs={6}
                  containerStyle="flex justify-between mt-1"
                  inputStyle={{
                    fontFamily: 'inherit',
                    fontWeight: 'inherit',
                    color: 'inherit',
                    margin: 0,
                    appearance: 'none',
                    backgroundColor: '#fff',
                    borderWidth: '1px',
                    paddingTop: '0.5rem',
                    paddingRight: '0.75rem',
                    paddingBottom: '0.5rem',
                    paddingLeft: '0.75rem',
                    borderRadius: '0.375rem',
                    borderColor: 'rgb(209 213 219 / 1)',
                    fontSize: '0.875rem',
                    lineHeight: '1.25rem',
                    width: '50px'
                  }}
                  disabledStyle="disabled:cursor-not-allowed disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-500"
                  isInputNum
                  shouldAutoFocus
                  value={otp}
                  onChange={setOtp}
                />
              </div>

              <div>
                <Button
                  text="Log in"
                  loading={authenticating}
                  fluid
                  onClick={() => { verifyAndLogIn() }}
                />
              </div>
            </div>
          )}
        </div>

        <div className="mt-4 text-center">
          <span className="text-xs text-gray-500">&copy; 2023 Nagarjun Nagaraj Palavalli</span>
        </div>
      </div>
    </div>
  )
}
