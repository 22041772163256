import { useState } from 'react'
import { PlusIcon } from '@heroicons/react/24/solid'
import { toast } from 'react-toastify'
import { useMutation, useLazyQuery } from '@apollo/client'
import { Modal, Button, Input } from "../../../../components/elements"
import { CREATE_GROUP, LIST_GROUPS_FOR_USER } from '../../../../graphql'
import { useGlobalStore } from '../../../../store'

// Local types
type CreateGroupModalProps = {
  onCreated: (id: string, name: string) => void
}

export function CreateGroupModal({ onCreated }: CreateGroupModalProps) {
  // Hooks
  const [createGroup, { loading }] = useMutation(CREATE_GROUP)
  const [listGroupsForUser] = useLazyQuery(LIST_GROUPS_FOR_USER, { fetchPolicy: 'network-only' })

  // Component state
  const updateAuthenticatedUserGroups = useGlobalStore((state) => state.updateAuthenticatedUserGroups)
  const [name, setName] = useState<string>('')

  // Functions
  const handleSubmission = async (closeModal: () => void) => {
    if (name.length === 0) {
      return
    }

    try {
      const response = await createGroup({
        variables: {
          name
        }
      })

      if (response.errors) {
        toast.error('An error occurred while creating the group. Please try again or contact support.')
        return
      }

      if (response.data?.createGroup) {
        // Re-fetch the authenticated user's groups
        const groupsResponse = await listGroupsForUser()
        if (groupsResponse.error || !groupsResponse.data?.listGroupsForUser) {
          toast.warn('Unable to refresh your groups. Please reload this page.')
        }
        updateAuthenticatedUserGroups(groupsResponse.data)

        closeModal()
        setName('')
        toast.success('Group successfully created.')
        onCreated(response.data.createGroup.id, response.data.createGroup.name)
      }
    } catch (error) {
      toast.error('An error occurred while creating the group. Please try again or contact support.')
    }
  }

  return (
    <Modal
      trigger={(
        <Button
          text="Group"
          icon={{
            element: <PlusIcon />,
            position: 'left'
          }}
          onClick={() => { }}
        />
      )}
      title="Create a group"
      description="You will automatically gain admin access to this group. You may add other admins or users to this group once it is created."
      primaryButton={{
        text: 'Create',
        disabled: name.length === 0,
        loading,
        onClick: (closeModal) => {
          handleSubmission(closeModal)
        }
      }}
    >
      <div className="my-4">
        <Input label="Name" value={name} onChange={(e) => { setName(e.target.value) }} />
      </div>
    </Modal>
  )
}
