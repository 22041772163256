import { graphql } from '../types/gql'

export const GET_SINGLE_USER = graphql(`
  query getUser($getUserId: String!) {
    getUser(id: $getUserId) {
      id
      firstName
      lastName
      phoneNumber
      email
      jobTitle
    }
  }
`)
