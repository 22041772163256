import DatePicker, { ReactDatePickerProps } from 'react-datepicker'

type DateTimePickerProps = ReactDatePickerProps & {
  label?: string
  hint?: string
}

export function DateTimePicker({ label, hint, ...props }: DateTimePickerProps) {
  return (
    <div>
      {(label || hint) && (
        <div className="flex justify-between mb-1">
          <label htmlFor="email" className="block text-sm font-medium text-gray-700">
            {label}
          </label>
          <span className="text-sm text-gray-500" id="email-optional">
            {hint}
          </span>
        </div>
      )}
      <div className="rounded-md shadow-sm">
        <DatePicker {...props} />
      </div>
    </div>
  )
}