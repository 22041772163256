import { PropsWithChildren, Fragment, SVGProps, useState, useEffect } from 'react'
import { useNavigate, NavLink } from 'react-router-dom'
import classNames from 'classnames'
import { Dialog, Menu, Transition } from '@headlessui/react'
import {
  CheckCircleIcon, ClipboardDocumentListIcon, BanknotesIcon, BuildingOfficeIcon,
  Bars3Icon, ChevronUpDownIcon, XMarkIcon, ArrowLeftOnRectangleIcon
} from '@heroicons/react/24/outline'
import { Routes } from '../../types'
import { useGlobalStore } from '../../store'
import { firebaseAuth } from '../../firebase'
import { Logo } from '../../static-assets'
import { Button } from '../elements'

// Local types
type FrameProps = PropsWithChildren<{
  module: 'Tasks' | 'Checklists' | 'Profit & Loss' | 'Admin'
}>

type NavRoute = {
  label: string
  to: Routes | string
}

type NavigationItem = NavRoute & {
  icon: (props: SVGProps<SVGSVGElement>) => JSX.Element
  children?: NavRoute[]
}

// Local data
// const userNavItems: NavRoute[] = [
//   { label: 'Account settings', to: Routes.Settings },
// ]

export function Frame({ module, children }: FrameProps) {
  // Hooks
  const navigate = useNavigate()

  // Component state
  const authenticatedUserInfo = useGlobalStore(state => state.authenticatedUserInfo)
  const authenticatedUserFullName = useGlobalStore(state => state.authenticatedUserFullName)
  const [mainNavItems, setMainNavItems] = useState<NavigationItem[]>([])
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  // Observers
  useEffect(() => {
    if (!authenticatedUserInfo) {
      navigate(Routes.LogIn)
    }

    // Construct main nav items based on user roles
    const navItemsToDisplay: NavigationItem[] = [
      { label: 'Tasks', to: `${Routes.Tasks}?filter=ALL`, icon: CheckCircleIcon },
      { label: 'Checklists', to: Routes.Checklists, icon: ClipboardDocumentListIcon },
      { label: 'P&L', to: Routes.PnL, icon: BanknotesIcon },
    ]
    if (authenticatedUserInfo?.getUserRolesSummary.isOrgAdmin) {
      navItemsToDisplay.push({ label: 'Admin', to: Routes.Admin, icon: BuildingOfficeIcon })
    }
    setMainNavItems(navItemsToDisplay)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Functions
  const logOut = async () => {
    await firebaseAuth.signOut()
    window.location.href = Routes.LogIn
  }

  if (!authenticatedUserInfo) {
    return null
  }

  return (
    <div className="flex h-full flex-col">
      {/* Top nav */}
      <header className="relative flex h-16 flex-shrink-0 items-center bg-white">
        {/* Logo area */}
        <div className="absolute inset-y-0 left-0 md:static md:flex-shrink-0">
          <NavLink
            to={Routes.Tasks + '?filter=ALL'}
            className="flex h-16 w-16 items-center justify-center bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-600 md:w-20"
          >
            <Logo />
          </NavLink>
        </div>

        {/* Menu button area */}
        <div className="absolute inset-y-0 right-0 flex items-center pr-4 sm:pr-6 md:hidden">
          {/* Mobile menu button */}
          <button
            type="button"
            className="-mr-2 inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-600"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
          </button>
        </div>

        {/* Desktop nav area */}
        <div className="hidden md:flex md:min-w-0 md:flex-1 md:items-center md:justify-between">
          <div className="min-w-0 flex-1 pl-4">
            <div className="flex gap-2 items-center text-gray-600">
              {module === "Admin" && <BuildingOfficeIcon className="w-6 h-6" />}
              {module === "Checklists" && <ClipboardDocumentListIcon className="w-6 h-6" />}
              {module === "Profit & Loss" && <BanknotesIcon className="w-6 h-6" />}
              {module === "Tasks" && <CheckCircleIcon className="w-6 h-6" />}

              <span className="font-medium leading-none">{module} Module</span>
            </div>
          </div>
          <div className="ml-10 flex flex-shrink-0 items-center space-x-10 pr-4">
            <div className="flex items-center space-x-8">
              {/* <span className="inline-flex">
                <a href="/" className="-mx-1 rounded-full bg-white p-1 text-gray-400 hover:text-gray-500">
                  <span className="sr-only">View notifications</span>
                  <BellIcon className="h-6 w-6" aria-hidden="true" />
                </a>
              </span> */}

              <Menu as="div" className="relative inline-block text-left">
                <Menu.Button className="flex gap-1 items-center bg-white text-sm focus:outline-none">
                  <span className="sr-only">Open user menu</span>
                  <div className="flex flex-col gap-0.5 items-end mr-3 text-xs">
                    <span className="text-gray-500">Logged in as</span>
                    <span className="font-medium">
                      {authenticatedUserFullName}
                    </span>
                  </div>
                  <img className="h-8 w-8 rounded-full" src="/assets/images/user-fallback-image.png" alt="Profile" />
                  <ChevronUpDownIcon className="w-4 h-4" />
                </Menu.Button>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1">
                      {/* {userNavItems.map(i => (
                        <Menu.Item key={i.label}>
                          {({ active }) => (
                            <NavLink
                              to={i.to}
                              className={classNames(
                                active ? 'bg-gray-100' : '',
                                'block px-4 py-2 text-sm text-gray-700'
                              )}
                            >
                              {i.label}
                            </NavLink>
                          )}
                        </Menu.Item>
                      ))} */}

                      <Menu.Item>
                        {({ active }) => (
                          <button
                            className={classNames(
                              active ? 'bg-gray-100' : '',
                              'block text-left px-4 py-2 text-sm text-gray-700 w-full'
                            )}
                            onClick={() => { logOut() }}
                          >
                            Log out
                          </button>
                        )}
                      </Menu.Item>
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </div>
        </div>

        {/* Mobile menu, show/hide this `div` based on menu open/closed state */}
        <Transition.Root show={mobileMenuOpen} as={Fragment}>
          <Dialog as="div" className="relative z-40 md:hidden" onClose={setMobileMenuOpen}>
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="hidden sm:fixed sm:inset-0 sm:block sm:bg-gray-600 sm:bg-opacity-75" />
            </Transition.Child>

            <div className="fixed inset-0 z-40">
              <Transition.Child
                as={Fragment}
                enter="transition ease-out duration-150 sm:ease-in-out sm:duration-300"
                enterFrom="transform opacity-0 scale-110 sm:translate-x-full sm:scale-100 sm:opacity-100"
                enterTo="transform opacity-100 scale-100  sm:translate-x-0 sm:scale-100 sm:opacity-100"
                leave="transition ease-in duration-150 sm:ease-in-out sm:duration-300"
                leaveFrom="transform opacity-100 scale-100 sm:translate-x-0 sm:scale-100 sm:opacity-100"
                leaveTo="transform opacity-0 scale-110  sm:translate-x-full sm:scale-100 sm:opacity-100"
              >
                <Dialog.Panel
                  className="fixed inset-0 z-40 h-full w-full bg-white sm:inset-y-0 sm:left-auto sm:right-0 sm:w-full sm:max-w-sm sm:shadow-lg"
                  aria-label="Global"
                >
                  <div className="flex h-16 items-center justify-between px-4 sm:px-6">
                    <NavLink to={Routes.Tasks}>
                      <img
                        className="block h-8 w-auto"
                        src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=500"
                        alt="Streamline"
                      />
                    </NavLink>
                    <button
                      type="button"
                      className="-mr-2 inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-600"
                      onClick={() => setMobileMenuOpen(false)}
                    >
                      <span className="sr-only">Close main menu</span>
                      <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <div className="max-w-8xl mx-auto py-3 px-2 sm:px-4">
                    {mainNavItems.map((item) => (
                      <Fragment key={item.label}>
                        <NavLink
                          to={item.to}
                          className="block rounded-md py-2 px-3 text-base font-medium text-gray-900 hover:bg-gray-100"
                        >
                          {item.label}
                        </NavLink>
                        {item.children ? item.children.map((child) => (
                          <NavLink
                            key={child.label}
                            to={child.to}
                            className="block rounded-md py-2 pl-5 pr-3 text-base font-medium text-gray-500 hover:bg-gray-100"
                          >
                            {child.label}
                          </NavLink>
                        )) : null}
                      </Fragment>
                    ))}
                  </div>
                  <div className="border-t border-gray-200 pt-4 pb-3">
                    <div className="max-w-8xl mx-auto flex items-center px-4 sm:px-6">
                      <div className="flex-shrink-0">
                        <img className="h-10 w-10 rounded-full" src="/assets/images/user-fallback-image.png" alt="Profile" />
                      </div>
                      <div className="ml-3 min-w-0 flex-1">
                        <div className="truncate text-base font-medium text-gray-800">
                          {authenticatedUserFullName}
                        </div>
                        <div className="truncate text-sm font-medium text-gray-500">
                          {authenticatedUserInfo.getAuthenticatedUser.phoneNumber}
                        </div>
                      </div>
                      {/* <a href="/" className="ml-auto flex-shrink-0 bg-white p-2 text-gray-400 hover:text-gray-500">
                        <span className="sr-only">View notifications</span>
                        <BellIcon className="h-6 w-6" aria-hidden="true" />
                      </a> */}
                    </div>
                    <div className="max-w-8xl mx-auto mt-5 space-y-1 px-2 sm:px-4">
                      <Button
                        variant="secondary"
                        fluid
                        icon={{
                          position: 'left',
                          element: <ArrowLeftOnRectangleIcon />
                        }}
                        text="Log out"
                        onClick={() => { logOut() }}
                      />
                    </div>
                    {/* <div className="max-w-8xl mx-auto mt-3 space-y-1 px-2 sm:px-4">
                      {userNavItems.map((item) => (
                        <NavLink
                          key={item.label}
                          to={item.to}
                          className="block rounded-md py-2 px-3 text-base font-medium text-gray-900 hover:bg-gray-50"
                        >
                          {item.label}
                        </NavLink>
                      ))}
                    </div> */}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
      </header>

      {/* Bottom section */}
      <div className="flex min-h-0 flex-1 overflow-hidden">
        {/* Narrow sidebar */}
        <nav aria-label="Sidebar" className="hidden md:block md:flex-shrink-0 md:overflow-y-auto md:bg-gray-800">
          <div className="relative flex w-20 flex-col space-y-3 p-3">
            {mainNavItems.map((item) => (
              <NavLink
                key={item.label}
                to={item.to}
                className={({ isActive }) => {
                  return classNames(
                    isActive ? 'bg-gray-900 text-white' : 'text-gray-400 hover:bg-gray-700',
                    'flex-shrink-0 inline-flex items-center justify-center h-14 w-14 rounded-lg'
                  )
                }}
              >
                <span className="sr-only">{item.label}</span>
                <item.icon className="h-6 w-6" aria-hidden="true" />
              </NavLink>
            ))}
          </div>
        </nav>

        {/* Main area */}
        <main className="min-w-0 flex-1 border-t border-gray-200 lg:flex">
          {children}
        </main>
      </div>
    </div>
  )
}
