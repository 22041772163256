/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

const documents = {
    "\n  mutation addUserToGroup($groupId: String!, $userId: String!, $role: PermissionRole) {\n    addUserToGroup(groupId: $groupId, userId: $userId, role: $role) {\n      id\n      name\n      jobTitle\n      phoneNumber\n      isAdmin\n    }\n  }\n": types.AddUserToGroupDocument,
    "\n  mutation createGroup($name: String!) {\n    createGroup(name: $name) {\n      id\n      name\n    }\n  }\n": types.CreateGroupDocument,
    "\n  mutation createTask($input: CreateTaskInput!) {\n    createTask(input: $input) {\n      id\n      title\n      description\n      dueDate\n      assignedTo {\n        id\n        name\n      }\n      group {\n        id\n        name\n      }\n      createdBy {\n        id\n        name\n      }\n    }\n  }\n": types.CreateTaskDocument,
    "\n  mutation createUser($input: CreateUserInput!) {\n    createUser(input: $input) {\n      id\n    }\n  }\n": types.CreateUserDocument,
    "\n  mutation deleteGroup($id: String!) {\n    deleteGroup(id: $id)\n  }\n": types.DeleteGroupDocument,
    "\n  mutation deleteTask($id: String!) {\n    deleteTask(id: $id)\n  }\n": types.DeleteTaskDocument,
    "\n  mutation deleteUser($userId: String!) {\n    deleteUser(id: $userId)\n  }\n": types.DeleteUserDocument,
    "\n  mutation editGroup($editGroupId: String!, $name: String!) {\n    editGroup(id: $editGroupId, name: $name) {\n      id\n      name\n    }\n  }\n": types.EditGroupDocument,
    "\n  mutation editTask($editTaskId: String!, $input: CreateTaskInput!) {\n    editTask(id: $editTaskId, input: $input) {\n      id\n      title\n      description\n      assignedTo {\n        id\n        name\n      }\n      group {\n        id\n        name\n      }\n      dueDate\n    }\n  }\n": types.EditTaskDocument,
    "\n  mutation editUser($userId: String!, $input: EditUserInput!) {\n    editUser(id: $userId, input: $input) {\n      id\n      firstName\n      lastName\n      phoneNumber\n      email\n      jobTitle\n    }\n  }\n": types.EditUserDocument,
    "\n  query getAuthenticatedUserInfo {\n    getAuthenticatedUser {\n      id\n      isDisabled\n      organisation\n      firstName\n      lastName\n      phoneNumber\n      email\n      timezone\n      jobTitle\n    }\n    getAuthenticatedUserOrganisation {\n      id\n      isDisabled\n      displayName\n      timezone\n    }\n    getUserRolesSummary {\n      isOrgAdmin\n      isGroupAdmin\n    }\n    listGroupsForUser {\n      count\n      groups {\n        id\n        name\n        isAdmin\n      }\n    }\n  }\n": types.GetAuthenticatedUserInfoDocument,
    "\n  query getSingleTask($id: String!) {\n    getSingleTask(id: $id) {\n      id\n      organisation\n      isDeleted\n      completedAt\n      title\n      description\n      assignedTo {\n        id\n        name\n      }\n      group {\n        id\n        name\n      }\n      dueDate\n      subtasks {\n        id\n        task\n        completed\n      }\n      activityLog {\n        id\n        type\n        actor\n        note\n        when\n      }\n      createdBy {\n        id\n        name\n      }\n      createdAt\n      updatedAt\n    }\n  }\n": types.GetSingleTaskDocument,
    "\n  query getUser($getUserId: String!) {\n    getUser(id: $getUserId) {\n      id\n      firstName\n      lastName\n      phoneNumber\n      email\n      jobTitle\n    }\n  }\n": types.GetUserDocument,
    "\n  query getTaskCountSummary {\n    getTaskCountSummary {\n      ALL\n      DUETODAY\n      NEXTSEVENDAYS\n      groups {\n        id\n        count\n      }\n    }\n  }\n": types.GetTaskCountSummaryDocument,
    "\n  mutation grantUserRoleForGroup($groupId: String!, $userId: String!, $role: PermissionRole!) {\n    grantUserRoleForGroup(groupId: $groupId, userId: $userId, role: $role)\n  }\n": types.GrantUserRoleForGroupDocument,
    "\n  query listAssignableUsers {\n    listAssignableUsers {\n      id\n      name\n    }\n  }\n": types.ListAssignableUsersDocument,
    "\n  query listGroupsForOrganisation($skip: Int!, $limit: Int!) {\n    listGroupsForOrganisation(skip: $skip, limit: $limit) {\n      count\n      groups {\n        id\n        name\n        admins\n        userCount\n      }\n    }\n  }\n": types.ListGroupsForOrganisationDocument,
    "\n  query listGroupsForUser($input: ListGroupsForUserInput) {\n    listGroupsForUser(input: $input) {\n      count\n      groups {\n        id\n        name\n        isAdmin\n      }\n    }\n  }\n": types.ListGroupsForUserDocument,
    "\n  query listTasks($input: ListTaskInput!) {\n    listTasks(input: $input) {\n      id\n      title\n      description\n      dueDate\n      assignedTo {\n        id\n        name\n      }\n      group {\n        id\n        name\n      }\n      createdBy {\n        id\n        name\n      }\n    }\n  }\n": types.ListTasksDocument,
    "\n  query listUsersForOrganisation($skip: Int!, $limit: Int!) {\n    listUsersForOrganisation(skip: $skip, limit: $limit) {\n      count\n      users {\n        id\n        fullName\n        mobileNumber\n        groups\n        extraGroupCount\n        isOrgAdmin\n      }\n    }\n  }\n": types.ListUsersForOrganisationDocument,
    "\n  query listUsersInGroup($groupId: String!) {\n    listUsersInGroup(groupId: $groupId) {\n      id\n      name\n      jobTitle\n      phoneNumber\n      isAdmin\n    }\n  }\n": types.ListUsersInGroupDocument,
    "\n  mutation markTaskAsCompleted($id: String!, $comment: String) {\n    markTaskAsCompleted(id: $id, comment: $comment)\n  }\n": types.MarkTaskAsCompletedDocument,
    "\n  mutation removeUserFromGroup($groupId: String!, $userId: String!) {\n    removeUserFromGroup(groupId: $groupId, userId: $userId)\n  }\n": types.RemoveUserFromGroupDocument,
    "\n  mutation reopenTask($id: String!) {\n    reopenTask(id: $id)\n  }\n": types.ReopenTaskDocument,
    "\n  mutation toggleSubTaskCompletion($input: ToggleSubTaskCompletionInput!) {\n    toggleSubTaskCompletion(input: $input) {\n      updatedSubTasks {\n        id\n        task\n        completed\n      }\n    }\n  }\n": types.ToggleSubTaskCompletionDocument,
};

export function graphql(source: "\n  mutation addUserToGroup($groupId: String!, $userId: String!, $role: PermissionRole) {\n    addUserToGroup(groupId: $groupId, userId: $userId, role: $role) {\n      id\n      name\n      jobTitle\n      phoneNumber\n      isAdmin\n    }\n  }\n"): (typeof documents)["\n  mutation addUserToGroup($groupId: String!, $userId: String!, $role: PermissionRole) {\n    addUserToGroup(groupId: $groupId, userId: $userId, role: $role) {\n      id\n      name\n      jobTitle\n      phoneNumber\n      isAdmin\n    }\n  }\n"];
export function graphql(source: "\n  mutation createGroup($name: String!) {\n    createGroup(name: $name) {\n      id\n      name\n    }\n  }\n"): (typeof documents)["\n  mutation createGroup($name: String!) {\n    createGroup(name: $name) {\n      id\n      name\n    }\n  }\n"];
export function graphql(source: "\n  mutation createTask($input: CreateTaskInput!) {\n    createTask(input: $input) {\n      id\n      title\n      description\n      dueDate\n      assignedTo {\n        id\n        name\n      }\n      group {\n        id\n        name\n      }\n      createdBy {\n        id\n        name\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation createTask($input: CreateTaskInput!) {\n    createTask(input: $input) {\n      id\n      title\n      description\n      dueDate\n      assignedTo {\n        id\n        name\n      }\n      group {\n        id\n        name\n      }\n      createdBy {\n        id\n        name\n      }\n    }\n  }\n"];
export function graphql(source: "\n  mutation createUser($input: CreateUserInput!) {\n    createUser(input: $input) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation createUser($input: CreateUserInput!) {\n    createUser(input: $input) {\n      id\n    }\n  }\n"];
export function graphql(source: "\n  mutation deleteGroup($id: String!) {\n    deleteGroup(id: $id)\n  }\n"): (typeof documents)["\n  mutation deleteGroup($id: String!) {\n    deleteGroup(id: $id)\n  }\n"];
export function graphql(source: "\n  mutation deleteTask($id: String!) {\n    deleteTask(id: $id)\n  }\n"): (typeof documents)["\n  mutation deleteTask($id: String!) {\n    deleteTask(id: $id)\n  }\n"];
export function graphql(source: "\n  mutation deleteUser($userId: String!) {\n    deleteUser(id: $userId)\n  }\n"): (typeof documents)["\n  mutation deleteUser($userId: String!) {\n    deleteUser(id: $userId)\n  }\n"];
export function graphql(source: "\n  mutation editGroup($editGroupId: String!, $name: String!) {\n    editGroup(id: $editGroupId, name: $name) {\n      id\n      name\n    }\n  }\n"): (typeof documents)["\n  mutation editGroup($editGroupId: String!, $name: String!) {\n    editGroup(id: $editGroupId, name: $name) {\n      id\n      name\n    }\n  }\n"];
export function graphql(source: "\n  mutation editTask($editTaskId: String!, $input: CreateTaskInput!) {\n    editTask(id: $editTaskId, input: $input) {\n      id\n      title\n      description\n      assignedTo {\n        id\n        name\n      }\n      group {\n        id\n        name\n      }\n      dueDate\n    }\n  }\n"): (typeof documents)["\n  mutation editTask($editTaskId: String!, $input: CreateTaskInput!) {\n    editTask(id: $editTaskId, input: $input) {\n      id\n      title\n      description\n      assignedTo {\n        id\n        name\n      }\n      group {\n        id\n        name\n      }\n      dueDate\n    }\n  }\n"];
export function graphql(source: "\n  mutation editUser($userId: String!, $input: EditUserInput!) {\n    editUser(id: $userId, input: $input) {\n      id\n      firstName\n      lastName\n      phoneNumber\n      email\n      jobTitle\n    }\n  }\n"): (typeof documents)["\n  mutation editUser($userId: String!, $input: EditUserInput!) {\n    editUser(id: $userId, input: $input) {\n      id\n      firstName\n      lastName\n      phoneNumber\n      email\n      jobTitle\n    }\n  }\n"];
export function graphql(source: "\n  query getAuthenticatedUserInfo {\n    getAuthenticatedUser {\n      id\n      isDisabled\n      organisation\n      firstName\n      lastName\n      phoneNumber\n      email\n      timezone\n      jobTitle\n    }\n    getAuthenticatedUserOrganisation {\n      id\n      isDisabled\n      displayName\n      timezone\n    }\n    getUserRolesSummary {\n      isOrgAdmin\n      isGroupAdmin\n    }\n    listGroupsForUser {\n      count\n      groups {\n        id\n        name\n        isAdmin\n      }\n    }\n  }\n"): (typeof documents)["\n  query getAuthenticatedUserInfo {\n    getAuthenticatedUser {\n      id\n      isDisabled\n      organisation\n      firstName\n      lastName\n      phoneNumber\n      email\n      timezone\n      jobTitle\n    }\n    getAuthenticatedUserOrganisation {\n      id\n      isDisabled\n      displayName\n      timezone\n    }\n    getUserRolesSummary {\n      isOrgAdmin\n      isGroupAdmin\n    }\n    listGroupsForUser {\n      count\n      groups {\n        id\n        name\n        isAdmin\n      }\n    }\n  }\n"];
export function graphql(source: "\n  query getSingleTask($id: String!) {\n    getSingleTask(id: $id) {\n      id\n      organisation\n      isDeleted\n      completedAt\n      title\n      description\n      assignedTo {\n        id\n        name\n      }\n      group {\n        id\n        name\n      }\n      dueDate\n      subtasks {\n        id\n        task\n        completed\n      }\n      activityLog {\n        id\n        type\n        actor\n        note\n        when\n      }\n      createdBy {\n        id\n        name\n      }\n      createdAt\n      updatedAt\n    }\n  }\n"): (typeof documents)["\n  query getSingleTask($id: String!) {\n    getSingleTask(id: $id) {\n      id\n      organisation\n      isDeleted\n      completedAt\n      title\n      description\n      assignedTo {\n        id\n        name\n      }\n      group {\n        id\n        name\n      }\n      dueDate\n      subtasks {\n        id\n        task\n        completed\n      }\n      activityLog {\n        id\n        type\n        actor\n        note\n        when\n      }\n      createdBy {\n        id\n        name\n      }\n      createdAt\n      updatedAt\n    }\n  }\n"];
export function graphql(source: "\n  query getUser($getUserId: String!) {\n    getUser(id: $getUserId) {\n      id\n      firstName\n      lastName\n      phoneNumber\n      email\n      jobTitle\n    }\n  }\n"): (typeof documents)["\n  query getUser($getUserId: String!) {\n    getUser(id: $getUserId) {\n      id\n      firstName\n      lastName\n      phoneNumber\n      email\n      jobTitle\n    }\n  }\n"];
export function graphql(source: "\n  query getTaskCountSummary {\n    getTaskCountSummary {\n      ALL\n      DUETODAY\n      NEXTSEVENDAYS\n      groups {\n        id\n        count\n      }\n    }\n  }\n"): (typeof documents)["\n  query getTaskCountSummary {\n    getTaskCountSummary {\n      ALL\n      DUETODAY\n      NEXTSEVENDAYS\n      groups {\n        id\n        count\n      }\n    }\n  }\n"];
export function graphql(source: "\n  mutation grantUserRoleForGroup($groupId: String!, $userId: String!, $role: PermissionRole!) {\n    grantUserRoleForGroup(groupId: $groupId, userId: $userId, role: $role)\n  }\n"): (typeof documents)["\n  mutation grantUserRoleForGroup($groupId: String!, $userId: String!, $role: PermissionRole!) {\n    grantUserRoleForGroup(groupId: $groupId, userId: $userId, role: $role)\n  }\n"];
export function graphql(source: "\n  query listAssignableUsers {\n    listAssignableUsers {\n      id\n      name\n    }\n  }\n"): (typeof documents)["\n  query listAssignableUsers {\n    listAssignableUsers {\n      id\n      name\n    }\n  }\n"];
export function graphql(source: "\n  query listGroupsForOrganisation($skip: Int!, $limit: Int!) {\n    listGroupsForOrganisation(skip: $skip, limit: $limit) {\n      count\n      groups {\n        id\n        name\n        admins\n        userCount\n      }\n    }\n  }\n"): (typeof documents)["\n  query listGroupsForOrganisation($skip: Int!, $limit: Int!) {\n    listGroupsForOrganisation(skip: $skip, limit: $limit) {\n      count\n      groups {\n        id\n        name\n        admins\n        userCount\n      }\n    }\n  }\n"];
export function graphql(source: "\n  query listGroupsForUser($input: ListGroupsForUserInput) {\n    listGroupsForUser(input: $input) {\n      count\n      groups {\n        id\n        name\n        isAdmin\n      }\n    }\n  }\n"): (typeof documents)["\n  query listGroupsForUser($input: ListGroupsForUserInput) {\n    listGroupsForUser(input: $input) {\n      count\n      groups {\n        id\n        name\n        isAdmin\n      }\n    }\n  }\n"];
export function graphql(source: "\n  query listTasks($input: ListTaskInput!) {\n    listTasks(input: $input) {\n      id\n      title\n      description\n      dueDate\n      assignedTo {\n        id\n        name\n      }\n      group {\n        id\n        name\n      }\n      createdBy {\n        id\n        name\n      }\n    }\n  }\n"): (typeof documents)["\n  query listTasks($input: ListTaskInput!) {\n    listTasks(input: $input) {\n      id\n      title\n      description\n      dueDate\n      assignedTo {\n        id\n        name\n      }\n      group {\n        id\n        name\n      }\n      createdBy {\n        id\n        name\n      }\n    }\n  }\n"];
export function graphql(source: "\n  query listUsersForOrganisation($skip: Int!, $limit: Int!) {\n    listUsersForOrganisation(skip: $skip, limit: $limit) {\n      count\n      users {\n        id\n        fullName\n        mobileNumber\n        groups\n        extraGroupCount\n        isOrgAdmin\n      }\n    }\n  }\n"): (typeof documents)["\n  query listUsersForOrganisation($skip: Int!, $limit: Int!) {\n    listUsersForOrganisation(skip: $skip, limit: $limit) {\n      count\n      users {\n        id\n        fullName\n        mobileNumber\n        groups\n        extraGroupCount\n        isOrgAdmin\n      }\n    }\n  }\n"];
export function graphql(source: "\n  query listUsersInGroup($groupId: String!) {\n    listUsersInGroup(groupId: $groupId) {\n      id\n      name\n      jobTitle\n      phoneNumber\n      isAdmin\n    }\n  }\n"): (typeof documents)["\n  query listUsersInGroup($groupId: String!) {\n    listUsersInGroup(groupId: $groupId) {\n      id\n      name\n      jobTitle\n      phoneNumber\n      isAdmin\n    }\n  }\n"];
export function graphql(source: "\n  mutation markTaskAsCompleted($id: String!, $comment: String) {\n    markTaskAsCompleted(id: $id, comment: $comment)\n  }\n"): (typeof documents)["\n  mutation markTaskAsCompleted($id: String!, $comment: String) {\n    markTaskAsCompleted(id: $id, comment: $comment)\n  }\n"];
export function graphql(source: "\n  mutation removeUserFromGroup($groupId: String!, $userId: String!) {\n    removeUserFromGroup(groupId: $groupId, userId: $userId)\n  }\n"): (typeof documents)["\n  mutation removeUserFromGroup($groupId: String!, $userId: String!) {\n    removeUserFromGroup(groupId: $groupId, userId: $userId)\n  }\n"];
export function graphql(source: "\n  mutation reopenTask($id: String!) {\n    reopenTask(id: $id)\n  }\n"): (typeof documents)["\n  mutation reopenTask($id: String!) {\n    reopenTask(id: $id)\n  }\n"];
export function graphql(source: "\n  mutation toggleSubTaskCompletion($input: ToggleSubTaskCompletionInput!) {\n    toggleSubTaskCompletion(input: $input) {\n      updatedSubTasks {\n        id\n        task\n        completed\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation toggleSubTaskCompletion($input: ToggleSubTaskCompletionInput!) {\n    toggleSubTaskCompletion(input: $input) {\n      updatedSubTasks {\n        id\n        task\n        completed\n      }\n    }\n  }\n"];

export function graphql(source: string): unknown;
export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;