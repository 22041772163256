import classNames from 'classnames'
import Skeleton from 'react-loading-skeleton'

export function TaskLoading() {
  return (
    <div
      className={classNames(
        'flex gap-4 items-start p-4 border border-gray-200',
        'bg-white hover:bg-gray-50',
        'hover:cursor-pointer w-full md:max-w-[90%] lg:max-w-[75%] rounded-l shadow-sm'
      )}
    >
      <Skeleton circle className="w-7 h-7" />
      <div className="flex flex-col gap-1 w-full overflow-hidden">
        <Skeleton className="w-56 h-5" />
        <Skeleton className="w-2/3 h-5" />

        <div className="flex flex-col lg:flex-row gap-2 lg:gap-5 mt-3 text-xs text-gray-500">
          <Skeleton className="w-32 h-4" />
          <Skeleton className="w-32 h-4" />
          <Skeleton className="w-32 h-4" />
        </div>
      </div>
    </div>
  )
}
